import Modal from "react-bootstrap/Modal";
import { CustomSelect } from "../../../shared/components/CustomSelect";
import styled from "styled-components";
import { useState } from "react";
import {
  addOtcMarketPlacePackage,
  getApprovers,
} from "../../../utils/services/user.service";
import { Ring } from "@uiball/loaders";
import { toast } from "react-toastify";
import { Button } from "@/shared/components/Button";

const CreateOtcMarketplacePackage = ({
  show,
  hide,
  reFetchPackages,
  otcId,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [chain_id, setChain_id] = useState(null);
  const [marketplaceInfo, setMarketplaceInfo] = useState({
    packageName: "",
    price: null,
    token: null,
    transfer_fee: null,
    description: "",
    contract_address: "",
    unlockDay: null,
  });
  const { packageName, price, token, description, unlockDay } = marketplaceInfo;
  const [packageToSell, setPackageToSell] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMarketplaceInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  const CreateNewOtcMarketplacePackage = async () => {
    try {
      if (
        !packageName ||
        !price ||
        !token ||
        !description ||
        !unlockDay ||
        !packageToSell
      ) {
        toast.error("Please fill in all required fields");
        return;
      }

      setIsLoading(true);
      const payload = {
        market_place_id: otcId,
        price: marketplaceInfo?.price,
        token: marketplaceInfo.token,
        package_name: marketplaceInfo.packageName,
        package_description: marketplaceInfo.description,
        unlock_days: unlockDay,
        want_sell: packageToSell,
      };

      const res = await addOtcMarketPlacePackage(payload);
      toast.success(res.data.Message);
      setIsLoading(false);
      reFetchPackages();
      hide();
    } catch (error) {
      // Handle error
      toast.error(error?.response?.data.Message);
      setIsLoading(false);
    }
  };
  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={hide}
      >
        <div
          className="loader-overlay"
          style={{
            display: isLoading ? "flex" : "none",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(255, 255, 255, 0.8)",
            zIndex: 999,
          }}
        >
          <Ring size={65} color="#00897B" />
        </div>
        <Modal.Header closeButton>
          <Modal.Title className="text-center" style={{ fontSize: "large" }}>
            <b> Create OTC Marketplace Package</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container padding-bottom-3x p-0">
            <div
              className=" mt-0 p-0 flex flex-col gap-8 justify-center items-center"
              style={{ boxShadow: "none", fontFamily: "sans-serif" }}
            >
              <div className=" flex flex-wrap justify-start gap-4 items-start">
                <div className="col-12 col-md-3">
                  <label>Package Name</label>
                  <InputDiv>
                    <ChatSearchInput
                      type="text"
                      name="packageName"
                      value={packageName}
                      className="form-control mt-2"
                      placeholder="Package Name"
                      onChange={handleChange}
                    />
                  </InputDiv>
                </div>

                <div className="col-12 col-md-3">
                  <label>Price</label>

                  <InputDiv>
                    <ChatSearchInput
                      type="text"
                      name="price"
                      value={price}
                      className="form-control mt-2"
                      placeholder=" Enter Price"
                      onChange={handleChange}
                    />
                  </InputDiv>
                </div>
                <div className="col-12 col-md-3">
                  <label>Unlock Days</label>

                  <InputDiv>
                    <ChatSearchInput
                      type="number"
                      name="unlockDay"
                      value={unlockDay}
                      className="form-control mt-2"
                      placeholder="Enter Unlock Days"
                      onChange={handleChange}
                    />
                  </InputDiv>
                </div>
                <div className="col-12 col-md-3">
                  <label>Package to Sell</label>

                  <InputDiv>
                    <ChatSearchInput
                      type="number"
                      name="packageToSell"
                      value={packageToSell}
                      className="form-control mt-2"
                      placeholder="Enter Package to Sell"
                      onChange={(e) => {
                        e.target.value > 0
                          ? setPackageToSell(e.target.value)
                          : setPackageToSell("");
                      }}
                    />
                  </InputDiv>
                </div>

                <div className="col-12 col-md-3">
                  <label>Token</label>

                  <InputDiv>
                    <ChatSearchInput
                      type="text"
                      name="token"
                      value={token}
                      className="form-control mt-2"
                      placeholder="Number Tokens"
                      onChange={handleChange}
                    />
                  </InputDiv>
                </div>

                <div className="col-12 col-md-3">
                  <label>Description</label>

                  <InputDiv>
                    <ChatSearchInput
                      type="text"
                      name="description"
                      value={description}
                      className="form-control mt-2"
                      placeholder="Enter Description"
                      onChange={handleChange}
                    />
                  </InputDiv>
                </div>
              </div>
              <div className=" flex justify-center items-center">
                <FormButton
                  type="submit"
                  variant="contain-success"
                  className="py-2 mt-2 w-100"
                  onClick={CreateNewOtcMarketplacePackage}
                >
                  Create New Package
                </FormButton>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default CreateOtcMarketplacePackage;
const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;
const DropdownDiv = styled.div`
  display: block;
  @media screen and (max-width: 876px) {
    width: 100%;
  }
`;
const FormButton = styled(Button)`
  color: "white";
  mergin-top: 1rem;
`;
const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;
