/* eslint-disable max-len */

import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  VERIFY_OTP,
  RESEND_OTP, CLEAR_REGISTER, FORGOT_PASS, SET_STATUS, 
  TWO_FACTOR, 
  CLEAR_FACTOR,
  COIN_LOAD,
  SET_COIN_LOAD,
  SET_WALLET_BALANCE,
} from '../actions/authActions';

const user = JSON.parse(localStorage.getItem('user'));

const initialState = user
  ? {
 isLoggedIn: true, user, statusCode: null, TwoFCode: null, coinLoad: false, walletBalance: null,
}
  : {
 isLoggedIn: false, user: null, isRegister: false, statusCode: null, TwoFCode: null, totalBalance: null, walletBalance: null, 
};
const authReducer = (state = initialState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
        isRegister: true,
      };
    case SET_WALLET_BALANCE:
      return {
        ...state,
        walletBalance: payload,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        isRegister: true,
      };
      case VERIFY_OTP:
      return {
        ...state,
        isLoggedIn: false,
        isRegister: true,
      };
      case RESEND_OTP:
        return {
          ...state,
          isLoggedIn: false,
          isRegister: true,
        };
        case FORGOT_PASS:
        return {
          ...state,
          isLoggedIn: false,
          isRegister: false,
        };
        case CLEAR_REGISTER:
          return {
            ...state,
            isLoggedIn: false,
            isRegister: false,
            statusCode: null,
          }; 
          
    case LOGIN_SUCCESS:
      return {
        ...state,
        isRegister: false,
        isLoggedIn: true,
        user: payload.user,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isRegister: false,
        isLoggedIn: false,
        user: error,
      };
    case LOGOUT:
      return {
        ...state,
        isRegister: false,
        isLoggedIn: false,
        user: null,
        TwoFCode: null,
      };
      case SET_STATUS:
        return { ...state, statusCode: payload };
        case TWO_FACTOR:
        return { ...state, TwoFCode: payload };
        case CLEAR_FACTOR:
          return { ...state, TwoFCode: null };
          case COIN_LOAD:
            return { ...state, coinLoad: true };
            case SET_COIN_LOAD:
          return { ...state, coinLoad: false };
    default:
      return state;
  }
};

export default authReducer;
