import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "../../../shared/components/Button";
import { Link } from "react-router-dom";
import AddGame from "./AddGame";
import { getGameList } from "../../../utils/services/user.service";
import moment from "moment";
import { getStatusBadge } from "../../../redux/constants/constans";
import DeleteGame from "./DeleteGame";
import EditGame from "./EditGame";

const CustodiyGames = () => {
    const [showAddGame, setShowAddGame] = useState(false)
    const [showDeleteGame, setShowDeleteGame] = useState(false)
    const [showEditGame, setShowEditGame] = useState(false)
    const [allGames, setAllGames] = useState([])
    const [reFetch, setRefetch] = useState(true)
    const [selectedGameId, setSelectedGameId] = useState("")
    const [selectedGame, setSelectedGame] = useState("")

    const getAllGames = async () => {
        try {
            const payload = {
                page_number: 1, limit: 10000
            }
            const res = await getGameList(payload)
            setAllGames(res?.data?.data)

        } catch (error) {
            console.log("🚀 ~ getAllGames ~ error:", error)

        }
    }
    useEffect(() => {
        getAllGames()
    }, [reFetch])

    return (
        <StyledCard>
            <Button onClick={() => setShowAddGame(true)} variant='outline-primary'>Add Game</Button>




            <div className="container mt-4">
                {/* <div className="row"> */}
                <div className="grid grid-cols-1 md:grid-cols-3  gap-4">
                    {allGames.map((game) => (
                        <div key={game.id} className="col-span-1 flex flex-col h-full">
                            <div className="card h-full flex flex-col justify-between">
                                <div className="flex min-h-[200px]">
                                    <img src={game?.image} alt="game" style={{
                                        objectFit:'contain'
                                    }} className="card-img-top " />
                                </div>
                                <div className="card-body items-start flex flex-col w-full gap-2">
                                    <h3 className="card-title">{game.title}</h3>
                                    {getStatusBadge(game.status)}
                                    {/* <p className="text-black">Symbol: {game.symbol}</p> */}
                                    <p className="text-black">Fee: {game.fee}{game.symbol}</p>
                                    <p className="text-black">Winner Reward: {game.reward_amount + game?.reward_symbol || 0}</p>
                                    <p className="text-black">Start: {moment(game.start_time).format('lll')}</p>
                                    <p className="text-black">End: {moment(game.end_time).format('lll')}</p>
                                </div>
                                <div className="flex flex-row w-full justify-center items-center gap-2">
                                    <Button variant="outline-primary">
                                        <Link to={`/games-detail?id=${game._id}`}>View</Link>
                                    </Button>
                                    <Button
                                        variant="outline-primary"
                                        onClick={() => {
                                            setSelectedGame(game);
                                            setShowEditGame(true);
                                        }}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        variant="outline-danger"
                                        onClick={() => {
                                            setSelectedGameId(game._id);
                                            setShowDeleteGame(true);
                                        }}
                                    >
                                        Delete
                                    </Button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>


                {/* </div> */}
            </div>


            {showAddGame &&
                <AddGame show={showAddGame} hide={() => setShowAddGame(false)} reFetch={() => setRefetch(!reFetch)} />
            }
            {showDeleteGame &&
                <DeleteGame show={showDeleteGame} hide={() => setShowDeleteGame(false)} data={selectedGameId} reFetch={() => setRefetch(!reFetch)} />
            }
            {showEditGame &&
                <EditGame show={showEditGame} hide={() => setShowEditGame(false)} data={selectedGame} reFetch={() => setRefetch(!reFetch)} />
            }
        </StyledCard>
    );
};

export default CustodiyGames;
export const StyledCard = styled.div`
    .card {
        margin-bottom: 20px;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
    .card-img-top {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    .card-body {
        text-align: center;
    }
`;