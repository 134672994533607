/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { NavLink, useHistory } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import PasswordField from '@/shared/components/form/Password';
import {
  FormGroup,
  FormGroupField,
  FormGroupIcon,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';

import renderCheckBoxField from '../../form/CheckBox';
import {
  AccountButton, AccountButtons, AccountForgotPassword, LoginForm,
} from '../AccountElements';
import FormField from '../../form/FormField';
import {
  clearMsg, clearTwoF, verifyTwoFactor,
} from '../../../../redux/actions/authActions';
import ApproveOtpForm from '../../../../containers/Dashboards/Operations/Contract/component/ApproveOtpForm';
import {
  ModalBody, ModalHeader, ModalTitle, StyledModal,
} from '../../../../containers/Dashboards/Operations/Contract/component/ContractTable';



const LogInForm = ({
  onSubmit, errorMsg, loading, typeFieldUser, isRegister,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // ////console.log('twoddd', twoFactor);
  const { TwoFCode } = useSelector(state => state.user);
  const [otpModal, setotpModal] = useState(TwoFCode === 'two_factor_auth');
  const [otpEmail, setotpEmail] = useState('');
  const [otpLoading, setOtpLoading] = useState(false);
  const [reCaptchaVal, setReCaptchaVal] = useState(null);

  const toggleOtp = () => {
    setotpModal(prevState => !prevState);
  };
  const onChangeRecaptcha = async (value) => {
    setReCaptchaVal(value);
  }
  useEffect(() => {
    if (TwoFCode === 'two_factor_auth') {
      setotpModal(true);
    }
    ////console.log('sta', TwoFCode);
  }, [TwoFCode]);
  const verifyOtp = (values) => {
    setOtpLoading(true);
    dispatch(verifyTwoFactor(
      values.otp,
      otpEmail,
    ))
      .then(() => {
        setOtpLoading(false);
        dispatch(clearTwoF());
        setotpModal(false);
        history.push('/dashboard');
        window.location.reload();
        setTimeout(() => {
          dispatch(clearMsg());
          dispatch(clearTwoF());
        }, 2000);
      })
      .catch(() => {
        setTimeout(() => {
          setOtpLoading(false);
          dispatch(clearMsg());
          dispatch(clearTwoF());
          setotpModal(false);
        }, 2000);
      });
  };
  return (
    <>
      <Form
        onSubmit={(values) => { onSubmit(values); setotpEmail(values.email); }}
        autoComplete="off"
        validate={(values) => {
          const errors = {};
          const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (!values.email) {
            errors.email = 'Required email !!';
          } else if (!emailRegex.test(values.email)) {
            errors.email = 'Invalid Email Field must contain @ and .';
          }
          // eslint-disable-next-line no-useless-escape
          const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?/~`])\S+$/;

          if (!values.password) {
            errors.password = 'Required password !!';
          } else if (values.password.length < 8) {
            errors.password = 'Password must be 8 characters long';
          } else if (!passwordRegex.test(values.password)) {
            errors.password = 'Password must contain at least one Uppercase,Lowercase,number,and special character';
          }
          return errors;
        }}
      >
        {({ handleSubmit }) => (

          <LoginForm
            onSubmit={handleSubmit}
            autoComplete="off"
          >

            <Alert
              variant="danger"
              show={!!(!isRegister && errorMsg !== undefined)}
              style={{
                marginTop: '1rem', color: 'red', width: '100%', textAlign: 'center',
              }}
              onClose={() => dispatch(clearMsg())}
              dismissible
            >
              {errorMsg}
            </Alert>

            {!otpModal &&
              <><FormGroup>
                <FormGroupLabel>Email</FormGroupLabel>
                <FormGroupField>
                  <FormGroupIcon>
                    <AccountOutlineIcon />
                  </FormGroupIcon>
                  <Field
                    name="email"
                    component={FormField}
                    type={typeFieldUser}
                    placeholder="Email"
                    className="input-without-border-radius"
                    autoComplete="off" />
                </FormGroupField>

              </FormGroup><FormGroup>
                  <FormGroupLabel>Password</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="password"
                      component={PasswordField}
                      placeholder="Password"
                      className="input-without-border-radius"
                      keyIcon />
                    {/* <AccountForgotPassword>
      <NavLink to="/reset_password">Forgot a password?</NavLink>
    </AccountForgotPassword> */}
                  </FormGroupField>
                </FormGroup><FormGroup>
                  <FormGroupField>
                    <Field
                      name="remember_me"
                      render={renderCheckBoxField}
                      label="Remember me"
                      type="checkbox" />
                  </FormGroupField>
                </FormGroup></>}

            {otpModal && <ApproveOtpForm
              onClose={() => { dispatch(clearTwoF); dispatch(clearMsg); }}
              onSubmit={values => verifyOtp(values)}
              errorMsg={errorMsg}
              loading={otpLoading}
              status={TwoFCode}
            />}
            <AccountButtons>
              {reCaptchaVal ? (
                loading ? (
                  <AccountButton variant="secondary">Loading.....</AccountButton>
                ) : (
                  <>
                    <AccountButton type="submit" variant="primary">
                      Login
                    </AccountButton>
                    {/* <AccountButton
                      as={NavLink}
                      variant="outline-primary"
                      to="/register"
                    >
                      Create Account
                    </AccountButton> */}
                  </>
                )
              ) : (
                <ReCAPTCHA
                  sitekey="6LcoJEIpAAAAAFCnnk7H89Uy7fVKElSa9ga8AknU"
                  // sitekey="6LfZ2U8pAAAAAIOF3a4FMW8h7jEbm99sDVQMEKn0" 
                  onChange={onChangeRecaptcha}
                />
              )}
            </AccountButtons>
          </LoginForm>
        )}
      </Form>
      {/* <StyledModal
        show={otpModal}
        onHide={toggleOtp}
        backdrop="static"
      >
        <ModalHeader>
     
          <ModalTitle>Two Factor Auth</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <ApproveOtpForm
            onClose={() => { dispatch(clearTwoF); dispatch(clearMsg); }}
            onSubmit={values => verifyOtp(values)}
            errorMsg={errorMsg}
            loading={otpLoading}
            status={TwoFCode}
          />


        </ModalBody>

      </StyledModal> */}
    </>
  );
};

LogInForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  typeFieldUser: PropTypes.string,
};

LogInForm.defaultProps = {
  typeFieldUser: 'text',
};

export default LogInForm;
