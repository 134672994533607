import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  useTable, useGlobalFilter, usePagination, useSortBy, useResizeColumns, useRowSelect,
} from 'react-table';
import styled from 'styled-components';
import { scrollbarStyles } from '@/utils/palette';
import ReactTableHeader from './ReactTableHeader';
import BodyReactTable from './ReactTableBody';
import ReactTableFooter from './ReactTableFooter';
import ReactTableFilter from './ReactTableFilter';
import ReactTablePagination from './ReactTablePagination';
import { Table } from '../../TableElements';

const ReactTableConstructor = ({
  tableConfig, tableOptions, tableOptionalHook, dataLength, onPageChange,
}) => {
  const {
    isEditable,
    isResizable,
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize,
    placeholder,
    isPagination,
  } = tableConfig;

  // Define pageSize before using it in the useTable configuration
  const defaultPageSize = manualPageSize ? manualPageSize[0] : 10;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    state,
    rows,
    prepareRow,
    page,
    pageCount,
    pageOptions,
    gotoPage,
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    setPageSize,
    setGlobalFilter,
    withDragAndDrop,
    updateDraggableData,
    updateEditableData,

    state: { pageIndex, pageSize },
  } = useTable(
    {
      ...tableOptions,
      manualPagination: true, // Enable manual pagination
      pageCount: Math.ceil(dataLength / defaultPageSize), // Use defaultPageSize here
      initialState: {
        pageIndex: 0, // Start from page 1 (index 0)
        pageSize: defaultPageSize, // Use defaultPageSize here
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useResizeColumns,
    useRowSelect,
    ...tableOptionalHook,
  );

  // Trigger API call when pageIndex changes
  useEffect(() => {
    if (onPageChange) {
      onPageChange(pageIndex + 1); // Convert zero-based index to page number
    }
  }, [pageIndex, onPageChange]);

  return (
    <div>
      {withSearchEngine && (
        <ReactTableFilter
          rows={rows}
          setGlobalFilter={setGlobalFilter}
          setFilterValue={tableOptions.setFilterValue}
          globalFilter={state.globalFilter}
          placeholder={placeholder}
          dataLength={dataLength}
        />
      )}
      <TableWrap pagination={withPagination}>
        <Table
          {...getTableProps()}
          bordered
        >
          <ReactTableHeader
            headerGroups={headerGroups}
            isSortable={isSortable}
            isResizable={isResizable}
          />
          <BodyReactTable
            page={page}
            getTableBodyProps={getTableBodyProps}
            prepareRow={prepareRow}
            updateDraggableData={updateDraggableData}
            updateEditableData={updateEditableData}
            isEditable={isEditable}
            withDragAndDrop={withDragAndDrop}
          />
          {/* {(pageCount === (pageIndex + 1) || (!withPagination && rows.length !== 0)) && (
            <ReactTableFooter
              footerGroups={footerGroups}
            />
          )} */}
        </Table>
      </TableWrap>
      {isPagination && withPagination && rows.length > 0 && (
        <ReactTablePagination
          page={page}
          gotoPage={gotoPage}
          previousPage={previousPage}
          nextPage={nextPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageOptions={pageOptions}
          pageSize={pageSize}
          pageIndex={pageIndex}
          pageCount={pageCount}
          setPageSize={setPageSize}
          manualPageSize={manualPageSize}
          dataLength={dataLength}
        />
      )}
    </div>
  );
};

ReactTableConstructor.propTypes = {
  tableConfig: PropTypes.shape({
    isEditable: PropTypes.bool,
    isResizable: PropTypes.bool,
    isSortable: PropTypes.bool,
    withDragAndDrop: PropTypes.bool,
    withPagination: PropTypes.bool,
    withSearchEngine: PropTypes.bool,
    manualPageSize: PropTypes.arrayOf(PropTypes.number),
    placeholder: PropTypes.string,
    isPagination: PropTypes.bool,
  }),
  tableOptions: PropTypes.shape({
    columns: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
    })),
    data: PropTypes.arrayOf(PropTypes.shape()),
    setFilterValue: PropTypes.func,
    updateDraggableData: PropTypes.func,
    updateEditableData: PropTypes.func,
    defaultColumn: PropTypes.oneOfType([
      PropTypes.any,
      PropTypes.shape({
        Cell: PropTypes.func,
      }).isRequired,
    ]),
    isEditable: PropTypes.bool,
    withDragAndDrop: PropTypes.bool,
    dataLength: PropTypes.number,
  }),
  tableOptionalHook: PropTypes.arrayOf(PropTypes.func).isRequired,
  dataLength: PropTypes.number.isRequired, // Ensure dataLength is required
  onPageChange: PropTypes.func.isRequired, // Callback for page changes
};

ReactTableConstructor.defaultProps = {
  tableConfig: {
    isEditable: false,
    isResizable: false,
    isSortable: false,
    withDragAndDrop: false,
    withPagination: false,
    withSearchEngine: false,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
    isPagination: false,
  },
  tableOptions: {
    columns: [],
    data: [],
    setFilterValue: () => {},
    updateDraggableData: () => {},
    updateEditableData: () => {},
    defaultColumn: [],
    withDragAndDrop: false,
    dataLength: null,
    disableSortBy: false,
    manualSortBy: false,
    manualGlobalFilter: false,
    manualPagination: false,
  },
};

export default ReactTableConstructor;

// region STYLES

const TableWrap = styled.div`
  overflow-x: auto;

  ${scrollbarStyles};

  ${props => (props.pagination ? `
    margin-bottom: 1rem;
  ` : `
    height: 458px;
  
    tbody {
      top: 30px;
    }
  `)}
`;

// endregion