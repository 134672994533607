import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import InfoItem from "../OtcMarketplace/InfoItem";
import { getGameDetailById } from "../../../utils/services/user.service";
import moment from "moment";
import ReactTableBase from "../../../shared/components/table/ReactTableBase";
import { getStatusBadge, tableConfig } from "../../../redux/constants/constans";

const gamesData = [
    {
        id: 1,
        name: "Game 1",
        image: "https://i.ytimg.com/vi/Ms55ICxDxpg/maxresdefault.jpg",
        status: "Active",
        startDate: "2025-01-01",
        endDate: "2025-01-31",
        participants: 150,
        winner: { name: "Alice Johnson", email: "alice@example.com" },
        reward: "$500",
        funding: "$10,000",
        fee: "$20",
    },
    {
        id: 2,
        name: "Game 2",
        image: "https://i.ytimg.com/vi/Ms55ICxDxpg/maxresdefault.jpg",
        status: "Ended",
        startDate: "2024-12-01",
        endDate: "2024-12-31",
        participants: 120,
        winner: { name: "Bob Smith", email: "bob@example.com" },
        reward: "$400",
        funding: "$8,500",
        fee: "$15",
    },
    {
        id: 3,
        name: "Game 3",
        image: "https://i.ytimg.com/vi/Ms55ICxDxpg/maxresdefault.jpg",
        status: "Upcoming",
        startDate: "2025-02-01",
        endDate: "2025-02-28",
        participants: 0, // For upcoming games, there might not be participants yet
        winner: { name: "TBD", email: "N/A" }, // Winner not yet determined
        reward: "$600",
        funding: "$12,000",
        fee: "$25",
    },
    {
        id: 4,
        name: "Game 4",
        image: "https://i.ytimg.com/vi/Ms55ICxDxpg/maxresdefault.jpg",
        status: "Active",
        startDate: "2025-01-15",
        endDate: "2025-02-15",
        participants: 180,
        winner: { name: "Charlie Brown", email: "charlie@example.com" },
        reward: "$700",
        funding: "$15,000",
        fee: "$30",
    },
];


const GameDetails = () => {
    const location = useLocation();

    // Extract query parameter from URL
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");
    const [game, setGame] = useState([])
    console.log("🚀 ~ GameDetails ~ game:", game)

    const getGameById = async () => {
        const payload = {
            game_id: id
        }
        const res = await getGameDetailById(payload)
        setGame(res?.data?.data)

    }

    useEffect(() => {
        getGameById()
    }, [id])

    if (!game) {
        return <p>Game not found.</p>;
    }


    const header = [
        { accessor: "date", Header: "Date" },
        { accessor: "user", Header: "User" },
        { accessor: "User_email", Header: "User Email" },
        { accessor: "payment_status", Header: "Payment Status" },
        { accessor: "total_count", Header: "Total Count" },
        { accessor: "fee", Header: "Fee" },
        { accessor: "winner_status", Header: "Winner Status" },

    ];

    const Ava = `/img/avat.png`;

    const data = [];
    game[0]?.game_details?.map((item) =>
        data.push({
            date: (
                <h6
                    className="text-capitalize"
                    style={{ color: "#999999", fontSize: "inherit" }}
                >
                    {moment(item.createdAt).format("ll") || "--"}
                </h6>
            ),
            User_email: (
                <h6
                    className="text-capitalize"
                    style={{ color: "#999999", fontSize: "inherit" }}
                >
                    {item?.user_detail[0]?.email || "--"}
                </h6>
            ),
            fee: (
                <h6
                    className="text-capitalize"
                    style={{ color: "#999999", fontSize: "inherit" }}
                >
                    {game[0]?.fee + game[0].symbol || "0"}
                </h6>
            ),
            total_count: (
                <h6
                    className="text-capitalize"
                    style={{ color: "#999999", fontSize: "inherit" }}
                >
                    {item?.counter || "0"}
                </h6>
            ),
            user: (
                <div className="flex flex-row pr-5 items-center  gap-3 ">
                    <img
                        className="flex h-[44px] w-[44px] rounded-3xl"
                        src={item?.user_detail[0]?.image || Ava}
                    />
                    <h6 className="text-capitalize text-nowrap">
                        {item?.user_detail[0]?.name}
                    </h6>
                </div>
            ),

            payment_status: (
                <h6
                    className="text-capitalize flex-col"
                    style={{ color: "#999999", fontSize: "inherit" }}
                >
                    {getStatusBadge(item.payment_status)}
                </h6>
            ),
            winner_status: (
                <h6
                    className="text-capitalize flex-col"
                    style={{ color: "#999999", fontSize: "inherit" }}
                >
                    {getStatusBadge(item.winner_status)}
                </h6>
            ),


        })
    );
    return (
        <div className="container mt-4">
            <div className="card">
                <img src={game[0]?.image} alt={'game'} className="card-img-top" style={{
                    height: '400px',
                    objectFit: 'contain'
                }} />

            </div>

            <div className="py-3 row text-center">


                {
                    game?.map((game) => {
                        return (
                            <div className="py-3 row text-center">
                                <InfoItem label="Title" value={`${game.title}`} />
                                <InfoItem label="End Time" value={moment(game.end_time).format('ll')} />
                                <InfoItem label="Start Time" value={moment(game.start_time).format('ll')} />
                                <InfoItem label="Fee" value={`${game.fee} ${game.symbol}`} />

                            </div>
                        )
                    })
                }

                <div className="flex overflow-scroll mb-4">
                    {game[0]?.game_details.length > 0 ? <ReactTableBase
                        columns={header}
                        data={data}
                        tableConfig={tableConfig}


                    /> : <h4>No data found</h4>}
                </div>


            </div>
        </div>
    );
};

export default GameDetails;
