import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { ContactProps, MessageProps } from '@/shared/prop-types/ChatProps';
import {
  colorAccent,
  colorAccentHover,
  colorAdditional,
  colorBubbleActive,
  colorText,
  colorBubble,
} from '@/utils/palette';
import {
 paddingRight, paddingLeft, right, left, 
} from '@/utils/directions';
const ChatBubble = ({ message, active }) => {
  const userEmailFromLocalStorage = localStorage.getItem('userEmail');
  //console.log("message hhh", message);
  const isCurrentUserMessage = message.user_detail[0].email === userEmailFromLocalStorage;
  const iconStyle = { color: 'rgb(69, 175, 149)' };
  return (
    <ChatBubbleWrap active={active} style={{ textTransform: 'capitalize' }}>
      <ChatBubbleMessageWrap
        file={message.file && message.file.length > 0}
        style={
          message.user_id === JSON.parse(localStorage.getItem('user')).data.user.user_id
            ? {
                background: 'rgba(45, 49, 57, 1)',
                marginLeft: 'auto',
                borderRadius: '15px 0 0 15px',
                padding: '15px 10px 10px 15px',
                paddingTop: '10px',
                paddingBottom: '5px',
                textColor:'white',
              }
            : {}
        }
      >
        <ChatBubbleContactName>{message.user_detail[0].name}</ChatBubbleContactName>
        {message.file && message.file.length > 0 && (
          <div>
            {message.file.map((file, index) => (
              <a key={index} href={file} target="_blank" rel="noopener noreferrer" className='ml-4' download>
                <FontAwesomeIcon icon={faDownload} size="1x" style={iconStyle} className="my-icon" />
              </a>
            ))}
          </div>
        )}
        <ChatBubbleMessage style={{ margin: '0 0 5px 0' }}>{message.message}</ChatBubbleMessage>
        <ChatBubbleDate>{moment(message.createdAt).format('LT')}</ChatBubbleDate>
      </ChatBubbleMessageWrap>
    </ChatBubbleWrap>
  );
};
ChatBubble.propTypes = {
  message: MessageProps.isRequired,
  active: PropTypes.bool,
};
ChatBubble.defaultProps = {
  active: false,
};
export default ChatBubble;
// region STYLES
const ChatBubbleWrap = styled.div`
  display: flex;
  margin-bottom: 20px;
  position: relative;
  flex-direction: ${props => (props.active ? 'row-reverse' : 'row')}; // Reverse direction for user messages
  ${props => props.active
    && `
    .chat__bubble-message-wrap {
      background-color: ${colorBubbleActive(props)};
      border-radius: 40px 0 20px 40px; // Adjust border-radius for your messages
    }
  `}
  &:last-child {
    margin-bottom: 0;
  }
`;
const ChatBubbleMessageWrap = styled.div`
  max-width: 570px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 0 15px 15px 0;
 
  ${paddingRight}: 15px;
  ${paddingLeft}: 15px;
  background-color: ${colorBubble};
  ${props => props.file && 'padding: 10px 20px 20px 20px'};
  img {
    max-width: 215px;
  }
`;
const ChatBubbleContactName = styled.p`
  margin-top: 0;
  margin-bottom: 2px;
  font-size: 13px;
  font-weight: 500;
  color: ${colorAccent};
  ${paddingRight}: 50px;
`;
const ChatBubbleDate = styled.p`
  color: ${colorAdditional};
  font-size: 10px;
  margin: 0;
  text-align: end;
  ${right}: 36px;
  @media screen and (min-width: 1366px) {
    ${right}: 0;
  }
`;
const ChatBubbleMessage = styled.p`
  margin: 0 0 5px 0;
  font-size: 12px;
  color: ${colorText};
`;
const ChatBubbleDownload = styled.a`
  color: ${colorAccent};
  font-weight: 500;
  &:hover {
    color: ${colorAccentHover};
  }
`;