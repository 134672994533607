import Modal from "react-bootstrap/Modal";
import styled from "styled-components";
import { useState } from "react";

import { Ring } from "@uiball/loaders";
import { toast } from "react-toastify";
import { Button } from "@/shared/components/Button";
import { CustomSelect } from "../../../shared/components/CustomSelect";
import {
  editTargetValue,
  setTargetValue,
} from "../../../utils/services/user.service";

const CreateTargetFinancialModal = ({ show, hide, reFetech }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState("");
  const [selectedOption, setOption] = useState("");
  const values = [
    { value: "amount", label: "Amount" },
    { value: "user", label: "User" },
    { value: "contract", label: "Contract" },
    { value: "safebox", label: "Safebox" },
    { value: "otc", label: "OTC" },
    { value: "store", label: "Store" },
  ];

  const editTargetFinancial = async () => {
    setIsLoading(true);
    try {
      const payload = { value: value, type: selectedOption };
      const res = await setTargetValue(payload);
      toast.success(res.data.Message);
      hide();
      reFetech();
      setIsLoading(false);
    } catch (error) {
      toast.error(error.response.data.Message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsLoading(false);

    }
  };

  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={hide}
      >
        <div
          className="loader-overlay"
          style={{
            display: isLoading ? "flex" : "none",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(255, 255, 255, 0.8)",
            zIndex: 999999,
          }}
        >
          <Ring size={65} color="#00897B" />
        </div>
        <Modal.Header closeButton>
          <Modal.Title className="text-center" style={{ fontSize: "large" }}>
            <b> Create New Value</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container padding-bottom-3x p-0">
            <div
              className=" mt-0 p-0 flex flex-col gap-8 justify-center items-center"
              style={{ boxShadow: "none", fontFamily: "sans-serif" }}
            >
              <div className="flex flex-col flex-md-row justify-between w-full gap-4">
                <div className=" col-12 col-md-6">
                  <InputDiv>
                    <label className="text-black">Enter Value</label>
                    <ChatSearchInput
                      type="number"
                      name="packageName"
                      value={value}
                      className="form-control mt-2"
                      placeholder="Enter Value "
                      onChange={(e) => setValue(e.target.value)}
                    />
                  </InputDiv>
                </div>
                <div className="col-12 col-md-6">
                  <InputDiv>
                    <label className="text-black">Select Type</label>

                    <CustomSelect
                      className={"flex w-full"}
                      value={selectedOption}
                      onChange={(value) => {
                        setOption(value.value);
                      }}
                      options={values}
                    />
                  </InputDiv>
                </div>
              </div>
              <div className=" flex justify-center items-center">
                <FormButton
                  type="submit"
                  variant="outline-success"
                  className="py-2 mt-2 w-100"
                  onClick={editTargetFinancial}
                >
                  Create
                </FormButton>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default CreateTargetFinancialModal;
const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;
const DropdownDiv = styled.div`
  display: block;
  @media screen and (max-width: 876px) {
    width: 100%;
  }
`;
const FormButton = styled(Button)`
  color: "white";
  mergin-top: 1rem;
`;
const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;
