/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import EditableTable from './component/EditableTable';
import { getSafebox, getSafeboxDetail } from '../../../../utils/services/user.service';
import { userBalance } from '../../../../utils/services/user.service'
import { Ring } from "@uiball/loaders";
const SafeBoxesOperations = () => {
  const [ListData, setListData] = useState();
  const [emailSearch, setemailSearch] = useState('');
  const [walletSearch, setwalletSearch] = useState('');
  const [symbolSearch, setSymbolSearch] = useState('');
  const [Symbol, setSymbol] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  //console.log(' userBalance ListData',ListData)
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)
        const payload = {
          email: emailSearch,
          wallet: walletSearch,

          limit: 10000,
          page_number: 1

        }
        const fees = await userBalance(payload);

        const data = fees.data.balances
        // console.log('Fees:', data); 
        setListData(data)
        setIsLoading(false)
      } catch (error) {
        setListData([])
        setIsLoading(false)
        // toast.error(error.Message)
        // console.error('Error fetching fees:', error);
      }
    };

    fetchData();
  }, [emailSearch, walletSearch])

useEffect(()=>{
})


  const fetchData = async () => {
    try {
      setIsLoading(true)
      const payload = {
        limit: 1,
        page_number: 1

      }
      const fees = await userBalance(payload);

      // console.log('Fees:', fees.data.balances); 
      const data = fees.data.balances
      setListData(data)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      // console.error('Error fetching fees:', error);
    }
  };
  return (<>
    <div
      className="loader-overlay"
      style={{
        display: isLoading ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        // background: 'rgba(255, 255, 255, 0.8)',
        zIndex: 999,
      }}
    >
      <Ring size={65} color="#00897B" />
    </div>
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Coin Balance</h3>
        </Col>
      </Row>
      <Row>
        <EditableTable emailSearch={emailSearch} symbolSearch={symbolSearch} Symbol={setSymbolSearch} walletSearch={walletSearch} Email={setemailSearch} Wallet={setwalletSearch} ListData={ListData} fetchData={fetchData} />
      </Row>

    </Container>

  </>)

};

export default SafeBoxesOperations;
