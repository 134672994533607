import Modal from "react-bootstrap/Modal";
import { CustomSelect } from "../../../shared/components/CustomSelect";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { Ring } from "@uiball/loaders";
import { toast } from "react-toastify";
import {
  addNewWallet,
  addNewWalletSol,
  editWallet,
  getWallet,
  sendOtp,
  varifyOtp_wallet,
} from "../../../utils/services/user.service";

const AddNewWallet = ({ show, hide }) => {
  const [searchUserId, setSearchUserId] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [isOtpRequired, setIsOtpRequired] = useState(true);
  const [newWalletAddress, setNewWalletAddress] = useState("");
  const [newPriveKey, setNewPrivateKey] = useState("");
  const [ID, setID] = useState("");
  const userData = JSON.parse(localStorage.getItem("user"));
  const usermail = userData?.data?.user?.email;

  const getOtp = async () => {
    setIsLoading(true);
    try {
      const res = await sendOtp();
      toast.success(res.data.message);
      setIsOtpRequired(false);
    } catch (error) {
      console.error("Error while getting OTP:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const checkWalletExistence = async () => {
    try {
      setIsLoading(true);
      const wallet = await getWallet();
      const walletAddress = wallet?.data?.wallets?.address;
      const PrivateKey = wallet?.data?.wallets?.privateKey;
      const Id = wallet?.data?.wallets?._id;
      setID(Id);
      // setNewPrivateKey(PrivateKey);
      // setNewWalletAddress(walletAddress);
      //   setWalletExists(!!wallet);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error checking wallet existence:", error);
    }
  };
  useEffect(() => {
    checkWalletExistence();
  }, []);

  const AddNewWallet = async () => {
    try {
      if (!newWalletAddress || !newPriveKey) {
        toast.error("All Fields are Required");
        return;
      }
      setIsLoading(true);
      const otpPayload = {
        email: usermail,
        otp: otp,
      };
      const verifyResponse = await varifyOtp_wallet(otpPayload);
      if (verifyResponse.data.status === "success") {
        const editPayload = {
          wallet_address: newWalletAddress,
          private_key: newPriveKey,
          type: "SOLANA"
        };
  
        const editResponse = await addNewWalletSol(editPayload);
        setOtp("");
        setIsOtpRequired(false);
        checkWalletExistence();
        hide()
        toast.success("Wallet added Successfully");
      }
    } catch (error) {
      // Handle any errors here
      console.error("Error adding new wallet:", error);
      toast.error("An error occurred while adding the wallet.");
    } finally {
      setIsLoading(false);
    }
  };
  

  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 99999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={hide}
      >
        <div
          className="loader-overlay"
          style={{
            display: isLoading ? "flex" : "none",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(255, 255, 255, 0.8)",
            zIndex: 999,
          }}
        >
          <Ring size={65} color="#00897B" />
        </div>
        <Modal.Header closeButton>
          <Modal.Title className="text-center" style={{ fontSize: "large" }}>
            <b>Add New Custodiy Wallet </b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container padding-bottom-3x p-0">
            <div
              className=" mt-0 p-0 flex flex-col gap-8 justify-center items-center"
              style={{ boxShadow: "none", fontFamily: "sans-serif" }}
            >
              <div className=" flex flex-wrap justify-start w-full gap-2 items-start">
                <div className="flex w-full flex-col ">
                  <label>Private Key</label>
                  <InputDiv>
                    <ChatSearchInput
                      type="text"
                      value={newPriveKey}
                      className="form-control "
                      placeholder="Private Key"
                      onChange={(e) => setNewPrivateKey(e.target.value)}
                    />
                  </InputDiv>
                </div>

                <div className="flex w-full flex-col">
                  <label>Wallet Address</label>

                  <InputDiv>
                    <ChatSearchInput
                      type="text"
                      value={newWalletAddress}
                      className="form-control "
                      placeholder="Wallet Address"
                      onChange={(e) => setNewWalletAddress(e.target.value)}
                    />
                  </InputDiv>
                </div>
                {!isOtpRequired && (
                  <div className="flex w-30 flex-col">
                    <label>Enter OTP</label>
                    <InputDiv>
                      <ChatSearchInput
                        type="number"
                        value={otp}
                        className="form-control "
                        placeholder="Enter OTP"
                        onChange={(e) => setOtp(e.target.value)}
                      />
                    </InputDiv>
                  </div>
                )}
              </div>
              <div className=" flex justify-center items-center">
                <button
                  className="text-white p-1 px-2 py-2 rounded-md bg-primary "
                  onClick={isOtpRequired ? getOtp : AddNewWallet}
                >
                  {isOtpRequired ? "Get OTP" : "Add Wallet"}
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default AddNewWallet;
const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;
const DropdownDiv = styled.div`
  display: block;
  @media screen and (max-width: 876px) {
    width: 100%;
  }
`;
const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;
