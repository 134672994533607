import { Card, Col, Modal } from "react-bootstrap";
import { ProfileCard } from "../Profile/ProfileBasicComponents";
import styled from "styled-components";
import { colorAdditional, colorBlue, colorBorder } from "../../../utils/palette";
import { Button } from "@/shared/components/Button";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import Cropper from "react-cropper";
import Tooltip from "@/shared/components/Tooltip";
import { SidebarLinkIcon } from "../../Layout/components/sidebar/SidebarLink";
import { getUserInfo, getUserInfo1, uploadProfile } from "../../../utils/services/user.service";


const MyProfile = () => {
    // const userInfo = JSON.parse(localStorage.getItem("user")).data.user
    const [userInfo,setUseruserInfo]=useState(null)
    const Ava = `/img/avat.png`;
    const [load, setload] = useState(false);
    const fileInputRef = useRef(null);
    const [selectedImage, setSelectedImage] = useState("");
    const [image1, setImage1] = useState(null);
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const [selectedImages, setSelectedImages] = useState([]);
    const [selectedImages1, setSelectedImages1] = useState([]);
    const [cropper, setCropper] = useState();

    const onCopyWalletAddress = (item) => {
        navigator.clipboard.writeText(item);
        toast.success('Text Copied !')
    };
    const dataURLtoBlob = (dataURL) => {
        const parts = dataURL.split(";base64,");
        const contentType = parts[0].split(":")[1];
        const raw = window.atob(parts[1]);
        const rawLength = raw.length;
        const uInt8Array = new Uint8Array(rawLength);
        for (let i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }
        return new Blob([uInt8Array], { type: contentType });
    };
    const handleCrop = async () => {
        setload(true);
        if (cropper) {
            const croppedCanvas = cropper.getCroppedCanvas();
            const dataUrl = croppedCanvas.toDataURL();
            const blob = dataURLtoBlob(dataUrl);
            const croppedFile = new File([blob], "cropped-image.jpg", {
                type: "image/jpeg",
            });

            const blobUrl = URL.createObjectURL(blob);

            if (!selectedImages1.includes(blobUrl)) {
                setSelectedImages(Array.from(croppedFile));
                setSelectedImages1((prevImages) => [...prevImages, croppedFile]);
                const formData = new FormData();
                formData.append("File", croppedFile);
                const res = await uploadProfile(formData);
                toast.success(res.data.message);
                // getUsers();
                fetchUserInfo()
                // setRefreshUserData(!refreshUserData);
                handleClose1();
                setload(false);
            }
        }
    };

    const handleButtonClickClick1 = () => {
        fileInputRef.current.click();
    };

    const handleImageUpload = (e) => {
        const selectedImagesArray = e.target.files;
        const selectedImage = e.target.files[0];
        setImage1(URL.createObjectURL(selectedImage));

        setShow1(true);
    };

    const fetchUserInfo = async () => {
        const res = await getUserInfo()

        setUseruserInfo(res?.data?.User)
    }
    useEffect(() => {
        fetchUserInfo()
    },[])


    return (
        <>
            <Col md={12} lg={12} xl={12}>
                <Card>
                    <ProfileCard>
                        <div className="d-lg-flex d-flex flex-lg-row justify-content-center align-items-lg-start align-items-center py-4  flex-column gap-3">
                            <div
                                className="profile-avatar position-relative  rounded-4   "
                                onClick={handleButtonClickClick1}
                                style={{ cursor: "pointer" }}
                            >
                                <img
                                    style={{
                                        width: "110px",
                                        height: "110px",
                                        objectFit: "cover",
                                        borderRadius: '50%'
                                    }}
                                    src={userInfo?.image || Ava}
                                    alt="avatar"
                                />
                                <div className="edit-icon position-absolute top-50 start-50 translate-middle">
                                    <i
                                        className="bi bi-pencil text-white "
                                        style={{ fontSize: "1rem" }}
                                    ></i>
                                </div>
                                <input
                                    type="file"
                                    accept="image/*"
                                    ref={fileInputRef}

                                    style={{ display: "none" }}
                                    onChange={handleImageUpload}
                                />

                            </div>

                            <div className="flex flex-row flex-wrap">
                                {/* <ProfileData> */}
                                <div className=" flex flex-col col-12 col-md-4">
                                    <strong className="text-white">Name:</strong>

                                    <ProfileName>
                                        {userInfo?.firstName}
                                        {userInfo?.lastName}
                                    </ProfileName>
                                </div>
                                <div className=" flex flex-col col-12 col-md-4">
                                    <strong className="text-white">Role:</strong>

                                    <ProfileName>
                                        {userInfo?.role}
                                    </ProfileName>
                                </div>

                                <div className=" flex flex-col col-12 col-md-4">
                                    <strong className="text-white">Email:</strong>

                                    <ProfileContact>
                                        {userInfo?.email}


                                        <Tooltip text="Copy Address" placement="top" style={{ cursor: "pointer" }}>
                                            <SidebarLinkIcon
                                                style={{ cursor: "pointer" }}
                                                className="ml-1 lnr lnr-book"
                                                onClick={() => onCopyWalletAddress(userInfo?.email)}
                                            />
                                        </Tooltip>

                                    </ProfileContact>
                                </div>
                                <div className=" flex flex-col col-12 col-md-4">
                                    <ProfileContact style={{ fontSize: "small" }}>

                                        <strong>Virtual Wallet</strong> <br />
                                        {/* <p>BNB/ETH/MATIC :</p> */}
                                        {`${userInfo?.address?.slice(
                                            0,
                                            15
                                        )}...${userInfo?.address?.slice(-5)}`}

                                        <Tooltip
                                            text="Copy Address"
                                            placement="top"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <SidebarLinkIcon
                                                style={{ cursor: "pointer" }}
                                                className="ml-1 lnr lnr-book"
                                                onClick={() =>
                                                    onCopyWalletAddress(
                                                        userInfo?.address
                                                    )
                                                }
                                            />
                                        </Tooltip>


                                        <br />


                                    </ProfileContact>
                                </div>
                                <div className=" flex flex-col col-12 col-md-4">
                                    <strong className="text-white">BTC Address:</strong>

                                    {userInfo?.btcAddress && <ProfileContact style={{ fontSize: "small" }}>
                                        {`${userInfo?.btcAddress?.slice(
                                            0,
                                            15
                                        )}...${userInfo?.btcAddress?.slice(-5)}`}

                                        <Tooltip text="Copy Address" placement="top" style={{ cursor: "pointer" }}>
                                            <SidebarLinkIcon
                                                style={{ cursor: "pointer" }}
                                                className="ml-1 lnr lnr-book"
                                                onClick={() => onCopyWalletAddress(userInfo?.btcAddress)}
                                            />
                                        </Tooltip>




                                    </ProfileContact>}
                                </div>
                                <div className=" flex flex-col col-12 col-md-4">
                                    <strong className="text-white">SOL Address:</strong>

                                    {userInfo?.secondary_wallet_sol_address && (
                                        <div>
                                            {userInfo?.secondary_wallet_sol_address && <ProfileContact style={{ fontSize: "small" }}>
                                                {`${userInfo?.secondary_wallet_sol_address?.slice(
                                                    0,
                                                    10
                                                )}...${userInfo?.secondary_wallet_sol_address?.slice(-5)}`}




                                                <Tooltip text="Copy Address" placement="top" style={{ cursor: "pointer" }}>
                                                    <SidebarLinkIcon
                                                        style={{ cursor: "pointer" }}
                                                        className="ml-1 lnr lnr-book"
                                                        onClick={() => onCopyWalletAddress(userInfo?.secondary_wallet_sol_address)}
                                                    />
                                                </Tooltip>
                                            </ProfileContact>}
                                            <span>

                                            </span>
                                        </div>
                                    )}





                                </div>


                                <div className=" flex flex-col col-12 col-md-4">

                                    {userInfo?.phone_number && <ProfileContact style={{ fontSize: "small" }}>
                                        <strong>Phone Number:</strong> <br />
                                        {userInfo?.phone_number}
                                    </ProfileContact>}
                                </div>
                                <div className=" flex flex-col col-12 col-md-4">
                                    {userInfo?.vat_number && <ProfileContact style={{ fontSize: "small" }}>
                                        <strong>Vat Number:</strong> <br />
                                        {userInfo?.vat_number}
                                    </ProfileContact>}
                                </div>




                            </div>






                        </div>
                    </ProfileCard>
                </Card>
            </Col>
            <Modal
                // size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                show={show1}
                onHide={handleClose1}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="text-center" style={{ fontSize: "large" }}>
                        Crop Image
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container padding-bottom-3x mb-1 p-0">
                        <div
                            className="card mt-0"
                            style={{ boxShadow: "none", fontFamily: "sans-serif" }}
                        >
                            <div className="card-body">
                                <div className="flex flex-row">
                                    <div className="col-md-12">
                                        <Cropper
                                            src={image1}
                                            aspectRatio={12 / 12}
                                            guides
                                            // crop={e => console.log(e.detail)}
                                            onInitialized={(instance) => {
                                                setCropper(instance);
                                            }}
                                        />
                                        <Button
                                            onClick={handleCrop}
                                            disabled={load}
                                            size="md"
                                            className="py-2 btn-block mt-2"
                                            variant="success"
                                        >
                                            Upload Image
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>


        </>
    )
}
export default MyProfile;


const ProfileInformation = styled.div`
  padding: 30px 10px;
  display: flex;
  text-align:left;
  border-bottom: 1px solid ${colorBorder};

  @media (max-width: 1345px) and (min-width: 1200px) {
    padding: 30px 15px;
  }

  @media screen and (max-width: 360px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
`;

const ProfileAvatar = styled.div`
  height: 110px;
  width: 140px;
  overflow: hidden;
  border-radius: 50%;

  img {
    height: 100%;
  }

  @media (max-width: 1345px) and (min-width: 1200px) {
    height: 130px;
    width: 130px;
  }
`;
const FormButton = styled(Button)`
 color:"white";
 mergin-top:1rem;
 `;
const ProfileData = styled.div`
  width: calc(100% - 0px);

  @media screen and (max-width: 360px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 0;
  }
`;

const ProfileName = styled.p`
  font-weight: 600;
  text-transform: capitalize;
  margin: 0;
  line-height: 18px;
`;

const ProfileWork = styled.p`
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 0;
  opacity: 0.6;
  line-height: 18px;
`;

const ProfileContact = styled.p`
  margin-top: 0;
  margin-bottom: 10px;
  line-height: 18px;
`;

const ProfileButton = styled(Button)`
  margin-top: 10px;
  margin-bottom: 0;
  padding: 8px 15px;
`;

const ProfileStats = styled.div`
  display: flex;
  justify-content: space-around;
`;

const ProfileStat = styled.div`
  text-align: center;
  padding-top: 5px;
  padding-bottom: 15px;
`;

const ProfileStatNumber = styled.p`
  color: ${colorBlue};
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
`;

const ProfileStatTitle = styled.p`
  margin: 0;
  color: ${colorAdditional};
  font-size: 12px;
  line-height: 14px;
`;
const HashLink = styled.button`
  border: none;
  background: transparent;
  color: #4ce1b6;
`;