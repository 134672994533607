import Modal from "react-bootstrap/Modal";
import { useState, useEffect } from "react";
import { Ring } from "@uiball/loaders";
import { Button } from "@/shared/components/Button";
import styled from "styled-components";
import { toast } from "react-toastify";
import { addPattner, editGame, getToken, startNewGame } from "../../../utils/services/user.service";
import { CustomSelect } from "../../../shared/components/CustomSelect";

// Helper function to format date
const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
};

const EditGame = ({ show, hide, reFetch, data }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [allSymbols, setAllSymbols] = useState([]);
    const [selectedSymbol, setSelectedSymbol] = useState(data?.symbol);
    const [fee, setFee] = useState(data?.fee); // State for fee
    const [startDate, setStartDate] = useState(formatDate(data?.start_time)); // Formatted start date
    const [endDate, setEndDate] = useState(formatDate(data?.end_time)); // Formatted end date
    const [title, setTitle] = useState(data?.title);
    const [selectedRewardSymbol, setSelectedRewardSymbol] = useState(data?.reward_symbol);
    const [totalReward, setTotalReward] = useState(data?.reward_amount);
    const modifiedResponse = allSymbols.map((item) => ({
        label: item.symbol,
        value: item.symbol,
    }));

    const handleSubmit = async () => {
        setIsLoading(true);
        try {
            if (!startDate || !endDate || !fee || !selectedSymbol || !title) {
                toast.error("All Fields are required");
                setIsLoading(false);
                return;
            }
            const payload = {
                start_time: startDate,
                end_time: endDate,
                fee: fee,
                symbol: selectedSymbol,
                record_id: data?._id,
                title: title,
                reward_symbol: selectedRewardSymbol,
                reward_amount:totalReward
            };
            const res = await editGame(payload);
            toast.success(res?.data?.message);
            setIsLoading(false);
            reFetch();
            hide();
        } catch (error) {
            setIsLoading(false);
            console.error("Error occurred:", error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const fees = await getToken();
                const data = fees?.data?.user;
                setAllSymbols(data);
            } catch (error) {
                console.error("Error fetching fees:", error);
            }
        };

        fetchData();
    }, []);

    return (
        <>
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
                onHide={hide}
            >
                <div
                    className="loader-overlay"
                    style={{
                        display: isLoading ? "flex" : "none",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        background: "rgba(255, 255, 255, 0.8)",
                        zIndex: 999,
                    }}
                >
                    <Ring size={65} color="#00897B" />
                </div>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center" style={{ fontSize: "large" }}>
                        <b>Edit Game </b>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container align-items-center justify-center w-full flex-col padding-bottom-3x p-0">
                        <div className="row my-2">
                            <div className="col-md-12 col-lg-4 col-sm-12">
                                <InputDiv>
                                    <label
                                        htmlFor="fee"
                                        style={{ fontSize: "small" }}
                                        className="form-label text-black"
                                    >
                                        Title *
                                    </label>
                                    <div>
                                        <ChatSearchInput
                                            type="text"
                                            value={title}
                                            name="title"
                                            id="title"
                                            placeholder="Enter Title"
                                            onChange={(e) => setTitle(e.target.value)}
                                            className={`form-control mt-2`}
                                        />
                                    </div>
                                </InputDiv>
                            </div>
                            <div className="col-md-12 col-lg-4 col-sm-12">
                                <InputDiv>
                                    <label
                                        htmlFor="fee"
                                        style={{ fontSize: "small" }}
                                        className="form-label text-black"
                                    >
                                        Total Reward *
                                    </label>
                                    <div>
                                        <ChatSearchInput
                                            type="number"
                                            value={totalReward}
                                            name="totalReward"
                                            id="totalReward"
                                            placeholder="Enter Total Reward"
                                            onChange={(e) => setTotalReward(e.target.value)}
                                            className={`form-control mt-2`}
                                        />
                                    </div>
                                </InputDiv>
                            </div>
                            <div className="col-md-12 col-lg-4 col-sm-12">
                                <InputDiv>
                                    <label
                                        htmlFor="symbol"
                                        style={{ fontSize: "small" }}
                                        className="form-label text-black"
                                    >
                                        Reward Symbol *
                                    </label>
                                </InputDiv>

                                <CustomSelect
                                    value={selectedRewardSymbol}
                                    onChange={(value) => {
                                        setSelectedRewardSymbol(value.label);
                                    }}
                                    placeholder={selectedRewardSymbol || "Select Symbol"}
                                    options={modifiedResponse}
                                />
                            </div>
                            <div className="col-md-12 col-lg-4 col-sm-12">
                                <InputDiv>
                                    <label
                                        htmlFor="fee"
                                        style={{ fontSize: "small" }}
                                        className="form-label text-black"
                                    >
                                        Fee *
                                    </label>
                                    <div>
                                        <ChatSearchInput
                                            type="text"
                                            value={fee}
                                            name="fee"
                                            id="fee"
                                            placeholder="Enter Fee"
                                            onChange={(e) => setFee(e.target.value)}
                                            className={`form-control mt-2`}
                                        />
                                    </div>
                                </InputDiv>
                            </div>
                            <div className="col-md-12 col-lg-4 col-sm-12">
                                <InputDiv>
                                    <label
                                        htmlFor="symbol"
                                        style={{ fontSize: "small" }}
                                        className="form-label text-black"
                                    >
                                        Symbol *
                                    </label>
                                </InputDiv>

                                <CustomSelect
                                    value={selectedSymbol}
                                    onChange={(value) => {
                                        setSelectedSymbol(value.label);
                                    }}
                                    placeholder={selectedSymbol || "Select Symbol"}
                                    options={modifiedResponse}
                                />
                            </div>

                            <div className="col-md-12 col-lg-4 col-sm-12">
                                <InputDiv>
                                    <label
                                        htmlFor="startDate"
                                        style={{ fontSize: "small" }}
                                        className="form-label text-black"
                                    >
                                        Start Date *
                                    </label>
                                    <div>
                                        <ChatSearchInput
                                            type="date"
                                            value={startDate}
                                            name="startDate"
                                            id="startDate"
                                            onChange={(e) => setStartDate(e.target.value)}
                                            className={`form-control mt-2`}
                                        />
                                    </div>
                                </InputDiv>
                            </div>

                            <div className="col-md-12 col-lg-4 col-sm-12">
                                <InputDiv>
                                    <label
                                        htmlFor="endDate"
                                        style={{ fontSize: "small" }}
                                        className="form-label text-black"
                                    >
                                        End Date *
                                    </label>
                                    <div>
                                        <ChatSearchInput
                                            type="date"
                                            value={endDate}
                                            name="endDate"
                                            id="endDate"
                                            onChange={(e) => setEndDate(e.target.value)}
                                            className={`form-control mt-2`}
                                        />
                                    </div>
                                </InputDiv>
                            </div>
                        </div>
                        <div className="self-center">
                            <Button
                                className="py-2 btn-block mb-0"
                                variant="contain-success"
                                size="md"
                                onClick={handleSubmit}
                            >
                                Submit
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default EditGame;

const StyledImage = styled.img`
  max-width: 100%;
`;
const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;
const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;
