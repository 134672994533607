import Modal from "react-bootstrap/Modal";
import styled from "styled-components";
import { useRef, useState } from "react";
import { addNewRecord } from "../../../utils/services/user.service";
import { Ring } from "@uiball/loaders";
import { toast } from "react-toastify";
import { Button } from "../../../shared/components/Button";

const AddMobileContentModal = ({ show, hide, reFetch }) => {
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [image, setImage] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [activeTab, setActiveTab] = useState("mobile"); // State for tabs
    const fileInputRef = useRef(null);

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImage(file);
            const blobUrl = URL.createObjectURL(file);
            setImageUrl(blobUrl);
        }
    };

    const AddRecordData = async () => {
        try {
            setIsLoading(true);
            const formData = new FormData();
            formData.append("title", title);
            if (activeTab === "mobile" && image) {
                formData.append("File", image); // Only include image for Mobile Content
            }
            formData.append("text", content);
            const res = await addNewRecord(formData);
            toast.success(res.data.Message);
            hide();
            setIsLoading(false);
            reFetch();
        } catch (error) {
            setIsLoading(false);
        }
    };

    return (
        <Modal size="md" centered show={show} onHide={hide}>
            <div
                className="loader-overlay"
                style={{
                    display: isLoading ? "flex" : "none",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "rgba(255, 255, 255, 0.8)",
                    zIndex: 999,
                }}
            >
                <Ring size={65} color="#00897B" />
            </div>

            <Modal.Title className="p-4 flex justify-between" style={{ fontSize: "large" }}>
                <b>Add Content</b>
                <i className="fa fa-times cursor-pointer" aria-hidden="true" onClick={hide}></i>
            </Modal.Title>

            {/* Tabs */}
            <div className="flex justify-center border-b">
                <Tab
                    className={activeTab === "mobile" ? "active-tab" : ""}
                    onClick={() => setActiveTab("mobile")}
                >
                    Mobile Content
                </Tab>
                <Tab
                    className={activeTab === "dashboard" ? "active-tab" : ""}
                    onClick={() => setActiveTab("dashboard")}
                >
                    App Dashboard
                </Tab>
            </div>

            <Modal.Body>
                <div className="container p-0">
                    <div className="flex flex-col" style={{ fontFamily: "sans-serif" }}>
                        {/* Banner Input - Only for Mobile Content */}
                        {activeTab === "mobile" && imageUrl && (
                            <img
                                className="h-[250px] object-cover rounded-md py-2"
                                src={imageUrl}
                            />
                        )}
                        {activeTab === "mobile" && (
                            <Button
                                variant="outline-primary"
                                className="mt-4"
                                onClick={handleButtonClick}
                            >
                                Upload Image
                            </Button>
                        )}
                        {activeTab === "mobile" && (
                            <input
                                type="file"
                                accept="image/*"
                                ref={fileInputRef}
                                hidden
                                onChange={handleImageUpload}
                            />
                        )}

                        {/* Common Inputs */}
                        <div className="flex flex-wrap items-start">
                            <InputDiv>
                                <ChatSearchInput
                                    type="text"
                                    value={title}
                                    placeholder="Enter Title"
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                            </InputDiv>
                            <InputDiv>
                                <TextArea
                                    value={content}
                                    placeholder="Enter Content"
                                    onChange={(e) => setContent(e.target.value)}
                                />
                            </InputDiv>
                        </div>

                        {/* Submit Button */}
                        <div className="flex justify-center items-center">
                            <Button variant="outline-primary" onClick={AddRecordData}>
                                Add Content
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default AddMobileContentModal;

// Styled Components
const Tab = styled.div`
  cursor: pointer;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 2px solid transparent;

  &.active-tab {
    border-bottom: 2px solid #00897b;
    color: #00897b;
  }

  &:hover {
    color: #00897b;
  }
`;

const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;

const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  color: #b1b1b1;

  &:focus {
    outline: none;
    border-bottom: 2px solid #47c9a4;
  }

  &::placeholder {
    color: #b1b1b1;
  }
`;
const TextArea = styled.textarea`
  width: 100%;
  min-height: 100px;
  font-size: 12px;
  padding: 5px;
  border: 1px solid #b1b1b1;
  border-radius: 4px;
  color: #b1b1b1;
  resize: vertical;

  &:focus {
    outline: none;
    border: 2px solid #47c9a4;
  }
`;
