import React, { useState } from 'react';
import { adminSwitchProfile} from "../../../utils/services/user.service";
function ProfileModal({ isOpen, onClose }) {
  const [Email, setEmail] = useState()
  // console.log("Email to change ", Email)
  if (!isOpen) return null;
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        email: Email, 
      };
      const response = await adminSwitchProfile(payload); 
      // console.log('response', response);
     if (response.data.data.user) { 
      localStorage.setItem('userData', JSON.stringify(response.data.data.user));
      const userData = response.data.data.user;
      const computedUserData = {
        userId: userData.user_id,
        address: userData.address,
        btcAddress: userData.btcAddress,
        fullName: `${userData.firstName} ${userData.lastName}`,
        email: userData.email,
        has2FA: userData.status_2fa,
      };
    const email = userData.email
      // Convert the computed user data to a query parameter string
      const queryParams = Object.keys(computedUserData)
        .map((key) => `${key}=${encodeURIComponent(computedUserData[key])}`)
        .join('&');
        const dashboardUrl = `http://192.168.18.12:3000/loginNew?email=${userData.email}`;
        const newTab = window.open(dashboardUrl, '_blank');
        if (newTab) {
          newTab.focus(); // Focus on the new tab if it was successfully opened
        } else {
          // Handle if the new tab couldn't be opened
          console.error('Failed to open a new tab.');
        }
        
      } else {
        console.error('Failed to open a new tab.');
        
      }
    } catch (error) {
     
      console.error('API Error:', error);
    }
  };
  
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 text-white"  >
   <div className="modal-container bg-gray-200 rounded-lg">
      {/* Modal content */}
      <div className=" p-8">
        <h2 className='text-dark'>Switch Account</h2>
        <form onSubmit={handleLogin}>
          <div className="mb-4">
            <label htmlFor="username" className="block font-medium text-gray-700">Email:</label>
            <input
              type="text"
              id="email"
              name="email"
              onChange={handleEmailChange}
              className="w-full px-3 py-2 border border-gray-300 rounded text-black"
              required
            />
          </div>
          {/* <div className="mb-4">
            <label htmlFor="password" className="block font-medium text-gray-700">Password:</label>
            <input
              type="password"
              id="password"
              name="password"
              className="w-full px-3 py-2 border border-gray-300 rounded"
              required
            />
          </div> */}
          <div className="flex justify-between">
            <button onClick={onClose} className="bg-blue-500  text-white font-bold py-2 px-4 rounded">Close</button>
          <button
            // type="button"
            className=" bg-green-500  text-white font-bold py-2 px-4 rounded"
          >
            Switch
          </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  );
}

export default ProfileModal;