import React, { useEffect, useState } from "react";
import TitleCard from "../../AddSafebox/TitleCard";
import { Ring } from "@uiball/loaders";
import {
  getUserInfo1,
  getSubAccounts,
  getAllTokens,
  getToken,
} from "../../../../utils/services/user.service";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "@/shared/components/Button";
import "../table.css";
import CreateSubAccount from "./CreateSubAccount";
import styled from "styled-components";
import DeleteSubAccount from "./DeleteSubAccount";
import UpdateSubAccountModal from "./UpdateSubAccountModal";
import ReactTableBase from "@/shared/components/table/ReactTableBase";
import moment from "moment/moment";
import Tooltip from "@/shared/components/Tooltip";
import { SidebarLinkIcon } from "../../../Layout/components/sidebar/SidebarLink";
import { Col, Form, Row } from "react-bootstrap";
import { CustomSelect } from "../../../../shared/components/CustomSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faUndo } from "@fortawesome/free-solid-svg-icons";

const AllSubAccounts = () => {
  const itemsPerPage = 20;
  const [myRequestData, setMyRequestData] = useState([]);
  const [tooltipText, setTooltipText] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);

  const [TFA, setTFA] = useState(false);
  const userData = JSON.parse(localStorage.getItem("user"));
  const usermail = userData?.data?.user?.email;
  const [isLoading, setIsLoading] = useState(false);
  const [selectedoptionorder, setoptionSelectorder] = useState("all");
  const [selectedOption, setSelectedOption] = useState("");
  const [show, setShow] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [userId, setUserId] = useState("");
  const [allSymbols, setAllSymbols] = useState([]);

  const [email, setEmail] = useState("");
  const [type, setType] = useState("");
  const [symbol, setSymbol] = useState("");

  const filteredRequestData =
  selectedoptionorder === "all"
  ? myRequestData
  : myRequestData.filter((store) => store.status == selectedoptionorder);

  const types = [
    { value: "same_quantity", label: "Same Quantity" },
    { value: "different_quantity", label: "Different Quantity" },
  ];


  useEffect(() => {
    const fetchData = async () => {
      const requestData = {
        email: usermail,
      };
      try {
        const response = await getUserInfo1(requestData);

        if (response.status === 200) {
          setTFA(response.data.User.TFA);
        } else {
          console.error("getUserInfo1 failed:", response);
        }
      } catch (error) {
        console.error("Error getUserInfo1:", error);
      }
    };
    fetchData();
  }, [usermail, TFA]);
  const onCopyWalletAddress = (item) => {
    navigator.clipboard.writeText(item);
    setTooltipText("Copied");
    setTimeout(() => {
      setTooltipText("");
    }, 5000); // Revert to original text after 5 seconds
  };
  const modifiedResponse = allSymbols.map((item) => ({
    label: item.symbol,
    value: item.symbol,
  }));
  useEffect(() => {
    const fetchData = async () => {
      try {
        const fees = await getToken(); 
        const data = fees?.data?.user
        setAllSymbols(data)
      } catch (error) {
        console.error('Error fetching fees:', error);
      }
    };

    fetchData(); 
  }, [])
  const Ava = `/img/avat.png`;

  const fetchAllAccountsRequests = async () => {
    try {
      setIsLoading(true);
      const payload = {
        limit: 10000,
        page_number: 1,
        user_id: userId || "",
        email: email || "",
        type: type || "",
        symbol: symbol || "",
      };
      const response = await getSubAccounts(payload);
      if (response) {
        setMyRequestData(response.data.data);
        const totalUsers = response.data.count;
        const pages = Math.ceil(totalUsers / itemsPerPage);
        setPageCount(pages);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Error fetching store data:");
      console.error("Error fetching store data:", error);
    }
  };
  useEffect(() => {
    fetchAllAccountsRequests();
  }, [currentPage]);

  const header = [
    { accessor: "name", Header: "Name" },
    { accessor: "request_id", Header: "User Id" },
    { accessor: "address", Header: "Address" },
    { accessor: "email", Header: "Email" },
    { accessor: "type", Header: "Type" },
    { accessor: "distribution_type", Header: "Distribution Type" },
    { accessor: "symbol", Header: "Symbol" },
    { accessor: "createdDate", Header: "Created Date" },
  ];
  const tableConfi2 = {
    isEditable: false,
    isSortable: false,
    isResizable: false,
    isPagination: true,
    withSearchEngine: false,
    manualPageSize: [10, 20, 30, 40],
    placeholder: "Search...",
    withPagination: true,
  };

  const data = [];
  filteredRequestData.map((item) =>
    data.push({
      request_id: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item._id}
        </h6>
      ),

      name: (
        <h6
          className="text-capitalize pr-2"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          <div className="flex flex-row pr-2 items-center gap-3 flex-nowrap ">
            <img
              className="flex h-[44px]  w-[44px] rounded-3xl"
              src={item?.parent_account_details[0].image || Ava}
            />
            <span className="flex text-nowrap">{item.parent_account_details[0].name || "--"}</span>
          </div>
        </h6>
      ),
      email: (
        <h6
          className=""
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item.parent_account_details[0].email || "--"}
        </h6>
      ),
      type: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item.account_type[0].type || "--"}
        </h6>
      ),
      symbol: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item.account_type[0].symbol || "--"}
        </h6>
      ),
      address: (
        <h6>
          {["SOLANA", "LTX", "WEN", "SoLpy"].includes(
            item.account_type[0].symbol
          ) ? (
            <Tooltip
              text={tooltipText || item.sol_address}
              placement="top"
              style={{ cursor: "pointer" }}
            >
              <span onClick={() => onCopyWalletAddress(item.sol_address)}>
                {item.sol_address
                  ? `${item.sol_address.slice(0, 5)}...${item.sol_address.slice(
                      -5
                    )}`
                  : "--"}
                <SidebarLinkIcon className="ml-1 lnr lnr-book" />
              </span>
            </Tooltip>
          ) : (
            <Tooltip
              text={tooltipText || item.address}
              placement="top"
              style={{ cursor: "pointer" }}
            >
              <span onClick={() => onCopyWalletAddress(item.address)}>
                {item.address
                  ? `${item.address.slice(0, 5)}...${item.address.slice(-5)}`
                  : "--"}
                <SidebarLinkIcon className="ml-1 lnr lnr-book" />
              </span>
            </Tooltip>
          )}
        </h6>
      ),

      distribution_type: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item.account_type[0].distribution_type || "--"}
        </h6>
      ),
      createdDate: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {moment(item.createdAt).format("MMM Do YY") || "--"}
        </h6>
      ),
    })
  );

  // Function to handle search
  const handleSearch = async () => {
    try {
      setIsLoading(true);
      const payload = {
        limit: 10000,
        page_number: 1,
        user_id: userId || "",
        email: email || "",
        type: type || "",
        symbol: symbol || "",
      };
      const response = await getSubAccounts(payload);
      if (response) {
        setMyRequestData(response.data.data);
        const totalUsers = response.data.count;
        const pages = Math.ceil(totalUsers / itemsPerPage);
        setPageCount(pages);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching products:", error);
      toast.error("Error fetching products");
    }
  };

  // Function to reset search fields
  const handleReset = async () => {
    setUserId("");
    setEmail("");
    setType("");
    setSymbol("");
    await fetchAllAccountsRequests();
  };

  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? "flex" : "none", // Use flex display when loading
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(255, 255, 255, 0.8)",
          zIndex: 999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <TitleCard title="" topMargin="mt-2">
        <h3 className="mb-4"> Sub Accounts</h3>
        <Row className="flex gap-2 md:gap-0">
          <Col xs={12} md={4} lg={3}>
            {/* Search input for store name */}
            <Form.Group className="flex gap-3" controlId="searchStoreName">
              <Form.Control
                type="text"
                placeholder="Search by User Id"
                value={userId}
                onChange={(e) => setUserId(e.target.value)}
                className="placeholder-[#807F7F] placeholder:text-sm bg-transparent"
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={4} lg={3}>
            {/* Search input for store ID */}
            <Form.Group className="flex gap-3" controlId="searchStoreId">
              <Form.Control
                type="text"
                placeholder="Search by Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="placeholder-[#807F7F] placeholder:text-sm bg-transparent"
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={4} lg={3}>
            <CustomSelect
              className={"flex w-full"}
              value={type}
              placeholder={type ? type : " Search by Type"}
              onChange={(value) => {
                setType(value.value);
              }}
              options={types}
            />
          </Col>
          <Col xs={12} md={4} lg={3}>
            <CustomSelect
              className={"flex w-full"}
              value={symbol}
              placeholder={symbol ? symbol : " Search by Symbol"}
              onChange={(value) => {
                setSymbol(value.label);
              }}
              options={modifiedResponse}
            />
          </Col>
        </Row>
        <Row>
          <Col
            md={3}
            className="flex w-full justify-center md:justify-start mt-4  gap-3"
          >
            <Button variant="success" onClick={handleSearch}>
              <FontAwesomeIcon icon={faSearch} className="mr-2" /> Search
            </Button>
            <Button variant="danger" onClick={handleReset}>
              <FontAwesomeIcon icon={faUndo} className="mr-2" /> Reset
            </Button>
          </Col>
        </Row>
        {/* <div className="lg:flex   "></div> */}
        <div className="overflow-x-auto w-full mt-2">
          <ReactTableBase
            columns={header}
            data={data}
            tableConfig={tableConfi2}
          />
        </div>
        {show && <CreateSubAccount show={show} hide={() => setShow(false)} />}
        {showDeleteModal && (
          <DeleteSubAccount
            show={showDeleteModal}
            hide={() => setShowDeleteModal(false)}
          />
        )}
        {showUpdateModal && (
          <UpdateSubAccountModal
            show={showUpdateModal}
            hide={() => setShowUpdateModal(false)}
          />
        )}
      </TitleCard>
    </>
  );
};

export default AllSubAccounts;
const FormButton = styled(Button)`
  color: "white";
  mergin-top: 1rem;
`;
