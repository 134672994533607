import Modal from "react-bootstrap/Modal";
import styled from "styled-components";
import { Button } from "../../../shared/components/Button";
import { updateService } from "../../../utils/services/user.service"; // Replace with your actual API call
import { toast } from "react-toastify";
import { Ring } from "@uiball/loaders";
import { useState, useRef, useEffect } from "react";
import { useChainContext } from "../../context/context";

const UpdateServiceModal = ({ show, hide, data }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [bannerPreview, setBannerPreview] = useState("");
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const fileInputRef = useRef(null);
    const { reFethData, setReFetchData } = useChainContext();
    useEffect(() => {
        if (data) {
            setBannerPreview(data.banner || "");
            setTitle(data.title || "");
            setDescription(data.description || "");
        }
    }, [data]);
    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => setBannerPreview(reader.result);
            reader.readAsDataURL(file);
        }
    };
    const handleSubmit = async () => {
        setIsLoading(true);
        try {
            const payload = {
                record_id: data?._id,
                title,
                description,
                // Uncomment the line below if you handle banners as URLs or another data type
                // banner: bannerPreview,
            };
    
            const res = await updateService(payload); // Ensure correct API function
            toast.success(res.data.Message || "Service updated successfully");
            hide();
            setReFetchData(!reFethData); // Trigger data refresh
        } catch (error) {
            toast.error(error?.response?.data.Message || "Failed to update service");
        } finally {
            setIsLoading(false);
        }
    };
    

    // const handleSubmit = async () => {
    //     setIsLoading(true);
    //     try {
    //         const formData = new FormData();
    //         formData.append("record_id", data?._id);
    //         formData.append("title", title);
    //         formData.append("description", description);
    //         // if (fileInputRef.current?.files[0]) {
    //         //     formData.append("banner", fileInputRef.current.files[0]);
    //         // }

    //         const res = await updateService(formData); // Ensure correct API function
    //         toast.success(res.data.Message || "Service updated successfully");
    //         hide();
    //         setReFetchData(!reFethData); // Trigger data refresh
    //     } catch (error) {
    //         toast.error(error?.response?.data.Message || "Failed to update service");
    //     } finally {
    //         setIsLoading(false);
    //     }
    // };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    return (
        <Modal size="lg" centered show={show} onHide={hide}>
            <div
                className="loader-overlay"
                style={{
                    display: isLoading ? "flex" : "none",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "rgba(255, 255, 255, 0.8)",
                    zIndex: 999,
                }}
            >
                <Ring size={65} color="#00897B" />
            </div>
            <ModalBody>
                <ModalTitle>Update Service</ModalTitle>
                {/* {bannerPreview && (
                        <img
                            src={bannerPreview}
                            alt="Service Banner"
                            className="banner-preview md:w-[30%] mb-4 w-full h-auto rounded-lg"
                        />
                    )} */}
                <div className="flex flex-row">
                 


                    {/* <Col xs={12} md={4} lg={4}>
                        <InputDiv>
                            <label style={{ fontSize: "small" }} className="form-label text-black mb-4">
                                Upload Service Banner
                            </label>
                            <Button variant="outline-primary" onClick={handleButtonClick}>
                                Upload Service Banner
                            </Button>
                            <input
                                type="file"
                                accept="image/*"
                                ref={fileInputRef}
                                hidden
                                onChange={handleImageUpload}
                            />
                        </InputDiv>
                    </Col> */}

                    <Col xs={12} md={4} lg={4}>
                        <InputDiv>
                            <label style={{ fontSize: "small" }} className="form-label text-black mb-4">
                                Service Title
                            </label>
                            <ChatSearchInput
                                type="text"
                                value={title}
                                placeholder="Enter Service Title"
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </InputDiv>
                    </Col>

                    <Col xs={12} md={4} lg={4}>
                        <InputDiv>
                            <label style={{ fontSize: "small" }} className="form-label text-black mb-1">
                                Description
                            </label>
                            <ChatSearchInput
                                as="textarea"
                                value={description}
                                placeholder="Enter Service Description"
                                onChange={(e) => setDescription(e.target.value)}
                                className="resize-vertical"
                            />
                        </InputDiv>
                    </Col>
                </div>
                <div className="text-center mt-6">
                    <Button variant="primary" onClick={handleSubmit}>
                        Update Service
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default UpdateServiceModal;

const ModalBody = styled.div`
  padding: 2rem;
  text-align: center;
`;

const ModalTitle = styled.h2`
  margin-bottom: 1.5rem;
  font-size: 18px;
  font-weight: bold;
  color: #333;
`;

const Col = styled.div`
  flex: 1;
  margin-right: 1rem;
  &:last-child {
    margin-right: 0;
  }
`;

const InputDiv = styled.div`
  margin-bottom: 1.5rem;
`;

const ChatSearchInput = styled.input`
  width: 100%;
  padding: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
`;

