import React, { useEffect, useState } from "react";
import { Modal } from "@mui/material";
import moment from "moment";
import { Ring } from "@uiball/loaders";
import {
  getAllTokens,
  getUserPendingBalance,
  transferGasBNB,
} from "../../../../utils/services/user.service";
import { Modal as BootstrapModal } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TitleCard from "../../../Dashboards/AddSafebox/TitleCard";
import { Button } from "@/shared/components/Button";
import styled from "styled-components";
import Tooltip from "@/shared/components/Tooltip";
import { SidebarLinkIcon } from "../../../Layout/components/sidebar/SidebarLink";
import TransferModal from "./TransferModal";
import TransferBalanceModal from "./TransferBalanceModal";
import { CustomSelect } from "@/shared/components/CustomSelect";
import numeral from "numeral";

const VirtualWalletTable = () => {
  const [balanceInfoData, setBaalanceInfoData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const userData = JSON.parse(localStorage?.getItem("user"));
  const userID = userData?.data?.user?.user_id;
  const [isLoading, setIsLoading] = useState(false);
  const [tooltipText, setTooltipText] = useState("");
  const [show, setShow] = useState(false);
  const [showBalanceModal, setShowBalanceModal] = useState(false);
  const [transferType, setType] = useState("");
  const [balanceCoin, setBalanceCoin] = useState("");
  const [userObject, setUserObject] = useState([]);
  const [emailFilter, setEmailFilter] = useState("");
  const [symbolFilter, setSymbolFilter] = useState("");
  const [allSymbols, setAllSymbols] = useState([]);
  const [selectedSymbol, setSelectedSymbol] = useState("");
  const [reFetch, setReFetch] = useState(false);

  const modifiedResponse = allSymbols.map((item) => ({
    label: item.label,
    value: item.label,
  }));

  const fetchAllTokens = async () => {
    const res = await getAllTokens();

    setAllSymbols(res.data.data);
  };
  useEffect(() => {
    fetchAllTokens();
  }, []);
  const handleEmailChange = (event) => {
    setEmailFilter(event.target.value);
  };

  const fetchBalance = async () => {
    try {
      setIsLoading(true);
      const payload = {
        limit: 50,
        page_number: 1,
        user_id: userID,
      };
      const response = await getUserPendingBalance(payload);
      if (response.data.data) {
        setBaalanceInfoData(response.data.data);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Error fetching store data:");
      console.error("Error fetching store data:", error);
    }
  };

  useEffect(() => {
    fetchBalance();
  }, [currentPage,reFetch]);

  const onCopyWalletAddress = (item) => {
    navigator.clipboard.writeText(item);
    setTooltipText("Copied");
    setTimeout(() => {
      setTooltipText("");
    }, 5000); // Revert to original text after 5 seconds
  };
  const filteredData = balanceInfoData.filter((item) => {
    const emailMatch = item.user[0]?.email
      .toLowerCase()
      .includes(emailFilter.toLowerCase());
    const symbolMatch = item.symbol
      .toLowerCase()
      .includes(selectedSymbol.toLowerCase());
    return emailMatch && symbolMatch;
  });

  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(255, 255, 255, 0.8)",
          zIndex: 999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <TitleCard title="" topMargin="mt-2">
        <h3 className="mb-4">Virtual Balance Info</h3>

        <h4 className="">Search by Filters</h4>
        <div className="flex py-4 justify-center items-center">
          <div className="col-lg-3 col-md-4 col-sm-12 mt-1">
            <InputDiv>
              <label htmlFor="email" className="text-white mb-3">
                Email
              </label>
              <ChatSearchInput
                name="email"
                type="text"
                placeholder="Filter by Email"
                value={emailFilter}
                onChange={handleEmailChange}
                className={`form-control w-100 `}
              />
            </InputDiv>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-12 mt-1">
            <SelectDiv style={{ display: "flex", width: "100%" }}>
              <label
                htmlFor="email"
                className="text-white"
                style={{ fontSize: "small" }}
              >
                Symbol
              </label>

              <CustomSelect
                value={selectedSymbol}
                onChange={(value) => {
                  setSelectedSymbol(value.value);
                }}
                options={modifiedResponse}
              />
            </SelectDiv>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-12 mt-3">
            <InputDiv>
              <label htmlFor="email" className="text-white">
                Reset
              </label>
              <FormButton
                type="submit"
                variant="outline-primary"
                className=" "
                onClick={() => {
                  setSelectedSymbol("");
                  setEmailFilter("");
                }}
              >
                Reset
              </FormButton>
            </InputDiv>
          </div>
        </div>
        <div className="lg:flex lg:space-x-4  "></div>
        <div className="flex flex-col md:flex-row gap-2 px-4 md:px-0 text-black">
          <FormButton
            type="submit"
            variant="contain-success"
            className="py-2 mt-2"
            onClick={() => {
              setType("ETH");
              setShow(true);
            }}
          >
            Transfer Gas ETH
          </FormButton>
          <FormButton
            type="submit"
            variant="outline-success"
            className="py-2 mt-2 "
            onClick={() => {
              setType("BNB");
              setShow(true);
            }}
          >
            Transfer Gas BNB
          </FormButton>
          <FormButton
            type="submit"
            variant="contain-success"
            className="py-2 mt-2 "
            onClick={() => {
              setType("MATIC");
              setShow(true);
            }}
          >
            Transfer Gas MATIC
          </FormButton>
          <FormButton
            type="submit"
            variant="outline-success"
            className="py-2 mt-2 "
            onClick={() => {
              setType("SOLANA");
              setShow(true);
            }}
          >
            Transfer Gas SOLANA
          </FormButton>
        </div>
        <div className="overflow-x-auto w-full">
          <table className="tbody table w-full ">
            <thead>
              <tr>
                <th>Balance ID</th>
                <th>Wallet Address</th>
                <th>User Name</th>
                <th>User Email</th>
                <th>Symbol</th>
                <th>Quantity</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.length > 0 ? (
                filteredData.map((item) => (
                  <tr key={item._id}>
                    <td>{item._id}</td>
                    <td>
                      <div className="flex justify-center items-center gap-2">
                        {`${item.user[0].address.slice(
                          0,
                          5
                        )}...${item.user[0].address.slice(-5)}`}
                        <Tooltip
                          text={tooltipText || item.user[0].address}
                          placement="top"
                          style={{ cursor: "pointer" }}
                        >
                          <SidebarLinkIcon
                            style={{ cursor: "pointer" }}
                            className="ml-1 lnr lnr-book"
                            onClick={() =>
                              onCopyWalletAddress(item.user[0].address)
                            }
                          />
                        </Tooltip>
                      </div>
                    </td>
                    <td>{item.user[0].name}</td>
                    <td>{item.user[0]?.email}</td>
                    <td>{item.symbol}</td>
                    <td>{numeral(item.quantity).format('0,0.00000')}</td>
                    <td>
                      <div>
                        <FormButton
                          type="submit"
                          variant="outline-primary"
                          className="py-2 mt-2 "
                          onClick={() => {
                            setBalanceCoin(item.symbol);
                            setShowBalanceModal(true);
                            setUserObject(item);
                          }}
                        >
                          Transfer Balance
                        </FormButton>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6">No Data Available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </TitleCard>

      {show && (
        <TransferModal
          show={show}
          hide={() => setShow(false)}
          transferType={transferType}
        />
      )}
      {showBalanceModal && (
        <TransferBalanceModal
          show={showBalanceModal}
          hide={() => setShowBalanceModal(false)}
          symbol={balanceCoin}
          userObject={userObject}
          reFetch={() => setReFetch(!reFetch)}

        />
      )}
    </>
  );
};

export default VirtualWalletTable;

const FormButton = styled(Button)`
  color: "white";
  mergin-top: 1rem;
`;
const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;
const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;
const SelectDiv = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 5px 0px;
  font-size: 14px;
`;
