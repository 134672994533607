/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable consistent-return */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import LogInForm from '@/shared/components/account/loginForm/LogInForm';
import {
  AccountCard,
  AccountContent,
  AccountHead,
  AccountLogo,
  AccountTitle,
  AccountWrap,
} from '@/shared/components/account/AccountElements';
import Tfaaa from '../MyAccount/Tfaaa';
import Tfa from '../MyAccount/Tfa';
import Logo from '../../../utils/img/custodiy.png';
import Modal from 'react-bootstrap/Modal';
import {
  getUserInfo1,
  login1,
  verifyTwoFactor1,
} from '../../../utils/services/user.service';
import {
  clearMsg, login, logout, clearTwoF, verifyTwoFactor,
} from '../../../redux/actions/authActions';
import ApproveOtpForm from '../../Dashboards/Operations/Contract/component/ApproveOtpForm';
import {
  ModalBody, ModalHeader, ModalTitle, StyledModal,
} from '../../Dashboards/Operations/Contract/component/ContractTable';
import { Ring } from '@uiball/loaders';

const LogIn = () => {
  const [loading, setLoading] = useState(false);

  const { isRegister } = useSelector(state => state.user);

  const { message } = useSelector(state => state.message);
  const dispatch = useDispatch();
  const history = useHistory();
  const rmCheck = document.getElementById('rememberMe');
  const emailInput = document.getElementById('email');
  const [tfaUrl, setTfaUrl] = useState(false);
  const [tfaEmail, setTfaEmail] = useState('');
  const [otpbtn, setOtpbtn] = useState(true);
  const [dataFromChild, setDataFromChild] = useState(false);
  const [otpModal, setotpModal] = useState(false);
  const [otpEmail, setotpEmail] = useState(JSON.parse(localStorage.getItem('newUser')));
  const { TwoFCode } = useSelector(state => state.user);
  const [otpLoading, setOtpLoading] = useState(false);
  const [otpEmail1, setUserEmail] = useState('');
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const [isLoading, setIsLoading] = useState(false);

  const toggleOtp = () => {
    setotpModal(prevState => !prevState);
  };

  const handleDataFromChild = (data) => {
    // console.log('Child to Parent');
    if (data === false) {
      setOtpbtn(true);
      setTfaUrl(false);
    }
    if (data === 0) {
      setOtpbtn(true);
      setTfaUrl(false);
      setShow1(true);
    }
  };

  // const verifyOtp = (values) => {
  //   setOtpLoading(true);
  //   dispatch(verifyTwoFactor(values.otp, otpEmail))
  //     .then((message1) => {
  //       setOtpLoading(false);
  //       dispatch(clearTwoF());
  //       setotpModal(false);
  //       history.push('/dashboard');
  //       window.location.reload();
  //       console.log('Success:', message1);
  //       setTimeout(() => {
  //         dispatch(clearMsg());
  //         dispatch(clearTwoF());
  //       }, 2000);
  //     })
  //     .catch((error) => {
  //       setOtpLoading(false);
  //       dispatch(clearMsg());
  //       dispatch(clearTwoF());
  //       setotpModal(false);
  //       console.error('Error:', error);
  //     });
  // };  

  const verifyOtp = async (values) => {
    const requestData = {
      otp: values.otp,
      email: otpEmail1,
    };
    try {
      setIsLoading(true)
      setLoading(true);
      const responseReject = await verifyTwoFactor1(requestData);
      if (responseReject.data) {
        setIsLoading(false)

        if (responseReject?.data?.status === "success") {
          localStorage.setItem('user', JSON.stringify(responseReject.data));
          setotpModal(false);

          history.push('/');
          window.location.reload();
        }


      }

    } catch (error) {
      setLoading(false);
      setotpModal(false);
      setIsLoading(false)


      if (error.response.data.status == 'TFA enabled') {
        setTfaUrl(true);
        setotpModal(false);
        setTfaEmail(otpEmail1);
      }
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const getUsers = async (email1) => {
    const requestData = {
      email: email1,
    };
    try {
      setLoading(true);
      const userInfoResponse = await getUserInfo1(requestData)


      if (userInfoResponse.data?.User.status_2fa) {
        setotpModal(true)
        return
      }
      if (userInfoResponse.data?.User.TFA) {
        setTfaUrl(true)
        return
      }


    } catch (error) {
      setLoading(false);
      console.error('Error getUserInfo:', error);

    }
  };


  // if (localStorage.checkbox && localStorage.checkbox !== '') {
  // rmCheck.setAttribute('checked', 'checked');
  // emailInput.value = localStorage.username;
  // } 
  // else {
  // rmCheck.removeAttribute('checked');
  // emailInput.value = '';
  // }

  // function lsRememberMe() {
  // if (rmCheck.checked && emailInput.value !== '') {
  //   localStorage.username = emailInput.value;
  //   localStorage.checkbox = rmCheck.value;
  // } else {
  //   localStorage.username = '';
  //   localStorage.checkbox = '';
  // }
  // }
  // const submitForm = async (values) => {
  //   // lsRememberMe();
  //   setUserEmail(values.email);
  //   setLoading(true);
  //   const requestData = {
  //     email: values.email,
  //     password: values.password,
  //     role: "admin"
  //   };
  //   try {
  //     const response = await login1(requestData);
  //     // console.log('Response login : ', response);
  //     localStorage.setItem('user', JSON.stringify(response.data));
  //     if (response.data.status === 'success') {
  //       if (dataFromChild === false) {
  //         getUsers(values.email);
  //       }
  //     }
  //     // if (response.status === 200) {
  //     //   setLoading(false);
  //     // } else {
  //     //   setLoading(false);
  //     //   console.error('Error rejecting order:', response.statusText);
  //     // }
  //     // setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //     // console.error('Response data:', error.response.data);
  //     if (error.response.data.status === 'not_verified') {
  //       history.push('/Webapistore');
  //     }
  //     if (error.response.data.status === 'two_factor_auth') {
  //       setotpModal(true);
  //     }
  //     toast.error(error.response.data.message, {
  //       position: toast.POSITION.TOP_RIGHT,
  //     });
  //     // console.error('Response status:', error.response.status);
  //     // console.error('Response headers:', error.response.headers);
  //   }
  //   setLoading(false);
  //   dispatch(clearMsg());

  //   //     dispatch(login(
  //   //       values.email,
  //   //       values.password,
  //   // ))
  //   // .then((response) => {
  //   //   console.log('Response login:', response);
  //   //    dispatch(clearMsg()); 
  //   //     // if (dataFromChild === false) {
  //   //     //     getUsers(values.email);
  //   //     //   }
  //   //       setInterval(() => {
  //   //     dispatch(logout());
  //   //   history.push('/login');
  //   //   }, 1800000);
  //   // })
  //   // .catch(() => {
  //   //   setTimeout(() => {
  //   //     dispatch(clearMsg());
  //   //   }, 3000);
  //   //   setLoading(false);
  //   // });
  // };


  const submitForm = async (values) => {
    // lsRememberMe();
    setUserEmail(values.email);
    setLoading(true);
    const requestData = {
      email: values.email,
      password: values.password,
      role: "admin"
    };
    try {
      const response = await login1(requestData);

      if (response.data?.status === "success") {
        localStorage.setItem('user', JSON.stringify(response.data));
        history.push('/dashboard')
        window.location.reload()
      }


    } catch (error) {

      if (error.response.data.status === 'two_factor_auth' ) {
        // getUsers(values.email)
        setotpModal(true)
        return
      }
      
      if(error.response.data.status === 'google_auth'){
        setTfaUrl(true)

      }
      else{
        toast.error(error?.response?.data?.message)
      }

    }




    setLoading(false);
    dispatch(clearMsg());

    //     dispatch(login(
    //       values.email,
    //       values.password,
    // ))
    // .then((response) => {
    //   console.log('Response login:', response);
    //    dispatch(clearMsg()); 
    //     // if (dataFromChild === false) {
    //     //     getUsers(values.email);
    //     //   }
    //       setInterval(() => {
    //     dispatch(logout());
    //   history.push('/login');
    //   }, 1800000);
    // })
    // .catch(() => {
    //   setTimeout(() => {
    //     dispatch(clearMsg());
    //   }, 3000);
    //   setLoading(false);
    // });
  };
  // if (isLoggedIn) {
  //   history.push('/dashboard');
  // } 
  // console.log('isLoa', isLoggedIn);
  return (
    <>
      <AccountWrap>
        <AccountContent>
          <AccountCard>

            <div>
              <div
                className="loader-overlay"
                style={{
                  display: isLoading ? 'flex' : 'none', // Use flex display when loading
                  justifyContent: 'center', // Center horizontally
                  alignItems: 'center', // Center vertically
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  background: 'rgba(255, 255, 255, 0.8)',
                  zIndex: 999,
                }}
              >
                <Ring size={65} color="#00897B" />
              </div>
              <AccountHead>
                <AccountTitle>
                  Welcome to
                  <AccountLogo>
                    <img style={{ width: '68%' }} src={Logo} alt="logo" />
                  </AccountLogo>
                </AccountTitle>
              </AccountHead>
              <h4 className="subhead" style={{ fontSize: '18px', fontWeight: '700', textAlign: 'center' }}>Log In</h4>
              {!otpModal && !tfaUrl && <LogInForm
                onSubmit={submitForm}
                form="log_in_form"
                loading={loading}
                errorMsg={message}
                isRegister={isRegister}
              />}

              {otpModal && <ApproveOtpForm
                onClose={() => { dispatch(clearTwoF); dispatch(clearMsg); }}
                onSubmit={values => verifyOtp(values)}
                // errorMsg={errorMsg}
                loading={otpLoading}
                status={TwoFCode}
              />}
            </div>

            <Tfa tfaUrl={tfaUrl} tfaEmail={otpEmail1} onDataFromChild={handleDataFromChild} />
          </AccountCard>
        </AccountContent>
      </AccountWrap>
      {/* <StyledModal
        show={otpModal}
        onHide={toggleOtp}
        backdrop="static"
      >
        <ModalHeader>
  

          <ModalTitle>Two Factor Auth <i onClick={() => { toggleOtp(); }} style={{ float: 'right', color: 'red' }} className="ml-1 fa fa-close" /></ModalTitle>
        </ModalHeader>
        <ModalBody>
          <ApproveOtpForm
            onClose={() => { dispatch(clearTwoF); dispatch(clearMsg); }}
            onSubmit={values => verifyOtp(values)}
            // errorMsg={errorMsg}
            loading={otpLoading}
            status={TwoFCode}
          />
        </ModalBody>
        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show1}
          onHide={handleClose1}
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-center" style={{ fontSize: 'large' }}><b>TFA Disabled </b></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container padding-bottom-3x p-0">
              <div className="card mt-0 p-0" style={{ boxShadow: 'none', fontFamily: 'sans-serif' }}>
                <div className="card-body p-0">
                  <div className="row">
                    <div className="col-12 text-success text-center">
                      <i className="fa fa-check-square-o mt-1" style={{ fontSize: '35px' }} aria-hidden="true" />
                    </div>
                    <div className="col-12 text-dark text-center">
                      <p className="text-dark fs-5">
                        TFA Authentication is successfully disabled
                      </p>
                      <p className="text-dark fs-5">
                        You can login without TFA
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </StyledModal> */}
    </>
  );
};

export default LogIn;
