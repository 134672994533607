import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import ReactTableBase from "@/shared/components/table/ReactTableBase";
import { Card, CardBody } from "@/shared/components/Card";
import styled from "styled-components";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { colorText } from "@/utils/palette";
import { Modal as BootstrapModal } from "react-bootstrap";
import Tfaaa from "../../../../../Account/MyAccount/Tfaaa";
import { flexFlow } from "@/utils/directions";
import { Button, ButtonToolbar } from "@/shared/components/Button";
import {
  editFee,
  addFee,
  getCategory,
  getUserInfo1,
  getAllTokens,
} from "../../../../../../utils/services/user.service";
import { Ring } from "@uiball/loaders";
import { CustomSelect } from "@/shared/components/CustomSelect";


const DataReactTable = ({ reactTableData, fetchData }) => {
  const symbols = ["CTY", "USDT (BEP 20)"];
  const symbolsWithEuro = ["CTY", "USDT (BEP 20)", "EUR"];
  const symbolsForElectionAndProject = ["CTY", "USDT (BEP 20)", "USDC"];
  const [rows, setData] = useState([]);
  const [isEditable, setIsEditable] = useState(false);
  const [isResizable, setIsResizable] = useState(false);
  const [withSearchEngine, setWithSearchEngine] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNewModalOpen, setIsNewModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [FeeCategory, setCategory] = useState([]);
  const [showSecondDropdown, setShowSecondDropdown] = useState(false);
  const [selectedFilterType, setSelectedFilterType] = useState("");

  const [editedFee, setEditedFee] = useState({
    fee_id: "",
    new_fee: "",
    new_percentage: "",
    symbol: "",
    type: "",
    categoryId: "",
  });
  const [newFee, setNewFee] = useState({
    fee: null,
    symbol: "",
    type: "",
    sub_type: "",
    percentage: null,
  });


  const [TFA, setTFA] = useState(false);
  const userData = JSON.parse(localStorage.getItem("user"));
  const usermail = userData?.data?.user?.email;
  const [tfaUrl, setTfaUrl] = useState(false);
  const [tfaEmail, setTfaEmail] = useState("");
  const [showTfaModal, setShowTfaModal] = useState(false);
  const [isNewMopdL, setisNewMopdL] = useState(false);
  const [allSymbols, setAllSymbols] = useState([])
  const [selectedType, setSelectedType] = useState("")
  const modifiedResponse = allSymbols.map(item => ({
    label: item.symbol,
    value: item.symbol
  }));
  const type = [
    "store_fee",
    "product_fee",
    "approver_fee",
    "custodiy_fee",
    "contract_fee",
    "safebox_fee",
    "sale_tax",
    "withdraw_fee",
    "election_fee",
    "project_fee",
    "game",
  ];
  const filterTypeOption = [
    { label: "All", value: "all" },
    { label: "Store Fee", value: "store_fee" },
    { label: "Product Fee", value: "product_fee" },
    { label: "Approver Fee", value: "approver_fee" },
    { label: "Custodiy Fee", value: "custodiy_fee" },
    { label: "Contract Fee", value: "contract_fee" },
    { label: "Safebox Fee", value: "safebox_fee" },
    { label: "Sale Tax", value: "sale_tax" },
    { label: "Withdraw Fee", value: "withdraw_fee" },
    { label: "Game Fee", value: "game" }
  ]

  useEffect(() => {
    setData(reactTableData)
    if (selectedFilterType) {
      if (selectedFilterType == "all") {
        setData(reactTableData)
        return
      }
      const filterData = reactTableData?.filter(data =>
        data.type === selectedFilterType
      )
      setData(filterData)
    }
  }, [reactTableData, selectedFilterType])
  const fetchAllTokens = async () => {
    const res = await getAllTokens();
    setAllSymbols(res.data.user);
  };
  useEffect(() => {
    fetchAllTokens();
  }, []);

  const renderTfaModal = () => {
    return (
      <BootstrapModal
        show={showTfaModal}
        onHide={() => setShowTfaModal(false)}
        centered
        className="dark:bg-gray-800"
      >
        <BootstrapModal.Header
          closeButton
          className="dark:border-gray-700 bg-dark text-white"
        >
          <BootstrapModal.Title>TFA Modal</BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body className="dark:text-white bg-dark">
          <Tfaaa
            tfaEmail={tfaEmail}
            tfaUrl={tfaUrl}
            onDataFromChild={handleDataFromChild}
          />
        </BootstrapModal.Body>
        <BootstrapModal.Footer className="dark:bg-gray-800 bg-dark dark:border-t dark:border-gray-700">
          <Button variant="secondary" onClick={() => setShowTfaModal(false)}>
            Close
          </Button>
        </BootstrapModal.Footer>
      </BootstrapModal>
    );
  };

  const handleDataFromChild = (data) => {
    localStorage.setItem("data", data);
    if (data === true) {
      setShowTfaModal(false);
      if (isNewMopdL === true) {
        setIsNewModalOpen(true);
        setisNewMopdL(false);
      } else {
        setIsModalOpen(true);
      }
    } else {
      setTfaUrl(true);
      setTfaEmail(userData.data.user.email);
    }
  };

  useEffect(() => {
    const storedData = localStorage.getItem("data");
    if (storedData === "true") {
      handleDataFromChild(false);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const requestData = {
        email: usermail,
      };

      try {
        const response = await getUserInfo1(requestData);

        if (response.status === 200) {
          setTFA(response.data.User.TFA);
        } else {
          console.error("getUserInfo1 failed:", response);
        }
      } catch (error) {
        console.error("Error getUserInfo1:", error);
      }
    };

    // Initial data fetch
    fetchData();

    // Set up interval to fetch data every second
    // const intervalId = setInterval(() => {
    fetchData();
    // }, 1000);

    // Cleanup function to clear the interval when the component is unmounted
    // return () => {
    //   clearInterval(intervalId);
    // };
  }, [usermail, TFA]);

  const openModal = (feeId) => {
    //console.log('feeId feeId feeId',feeId)
    const specificFee = reactTableData.find((fee) => fee._id === feeId);
    // console.log('specificFee',specificFee)
    if (TFA) {
      setTfaEmail(userData.data.user.email);
      setTfaUrl(true);
      setShowTfaModal(true);
      setEditedFee({
        fee_id: specificFee._id,
        new_fee: specificFee.fee,
        new_percentage: specificFee.percentage,
        symbol: specificFee.symbol,
        type: specificFee.type,
        categoryId: specificFee.sub_type,
      });
    } else {
      if (specificFee) {
        setEditedFee({
          fee_id: specificFee._id,
          new_fee: specificFee.fee,
          new_percentage: specificFee.percentage,
          symbol: specificFee.symbol,
          type: specificFee.type,
          categoryId: specificFee.sub_type,
        });
        setIsModalOpen(true);
      }
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setIsNewModalOpen(false);
  };
  useEffect(() => {
    const getCategorys = async () => {
      try {
        const response = await getCategory();
        if (response.data.category) {
          setCategory(response.data.category);
        } else {
          //console.log("category error");
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    getCategorys();
  }, []);


  const handleSave = async () => {
    try {

      if (
        ((!editedFee.new_fee || !editedFee.new_percentage) && selectedType == "withdraw_fee" || selectedType == "contract_fee") || (!editedFee.new_fee && selectedType == "approver_fee") ||
        !editedFee.symbol
        ||
        !editedFee.type
      ) {
        toast.error("All fields are required");
        setIsLoading(false);
        return;
      }
      setIsLoading(true);
      const res = await editFee(editedFee);
      toast.success(res?.data?.Message)
      fetchData();
      setIsModalOpen(false);
      setIsLoading(false);
    } catch (error) {
      // Handle error appropriately
      setIsLoading(false);
      console.error("Error editing fee:", error);
    }
  };

  const customColumns = [
    {
      Header: "#",
      accessor: "_id",
    },
    {
      Header: "Updated At",
      accessor: "updatedAt",
      Cell: ({ value }) => <span>{moment(value).format("MMMM Do YYYY")}</span>,
    },
    {
      Header: "Fee",
      accessor: "fee",
      Cell: ({ value }) => (
        <span>{value !== undefined ? value?.toString() : "0"}</span>
      ),
    },
    {
      Header: "Percentage",
      accessor: "percentage",
      Cell: ({ value }) => (
        <span>{value !== undefined ? value?.toString() : "0"}%</span>
      ),
    },
    {
      Header: "Symbol",
      accessor: "symbol",
    },
    {
      Header: "Type",
      accessor: "type",
      Cell: ({ value }) => {
        switch (value) {
          case "store_fee":
            return "Store Creation Fee";
          case "safebox_fee":
            return "Safe Box Creation Fee";
          case "custodiy_fee":
            return "Custodiy Fee";
          case "sale_tax":
            return "Sale Tax";
          case "approver_fee":
            return "Approver Fee";
          case "product_fee":
            return "Product Fee";
          case "contract_fee":
            return "Contract Fee";
          default:
            return <span>{value}</span>;
        }
      },
    },
    {
      Header: "sub_type",
      accessor: "sub_type",
      Cell: ({ value }) => {
        // Find the corresponding category name based on the sub_type id
        const categoryName = FeeCategory.find(
          (category) => category._id === value
        )?.category_name;

        return <span>{categoryName || "---"}</span>;
      },
    },
    {
      Header: "Edit",
      accessor: "edit",
      Cell: ({ row }) => (
        <div>
          <FontAwesomeIcon
            style={{ cursor: "pointer" }}
            icon={faEdit}
            onClick={() => openModal(row.original._id)}
          />
        </div>
      ),
    },
  ];

  const handleNewFee = () => {
    setNewFee({ fee: "", symbol: "", type: "", sub_type: "", percentage: "" });
    if (TFA) {
      setTfaEmail(userData.data.user.email);
      setTfaUrl(true);
      setShowTfaModal(true);
      setisNewMopdL(true);
    } else {
      setIsNewModalOpen(true);
    }
  };
  const handleNewFeeChange = (fieldName, value) => {
    setNewFee({ ...newFee, [fieldName]: value });
  };

  const handleSaveNewFee = async () => {
    try {
      // const symbolExists = reactTableData.some(fee => fee.symbol === newFee.symbol);
      // if (symbolExists) {
      //   toast.error("Symbol already exisits ")
      //   return
      // }
      // if (
      //   (!newFee.fee && selectedType != "withdraw_fee" ) ||
      //   (!newFee.fee && selectedType != "contract_fee" ) ||
      //   !newFee.symbol ||
      //   (!newFee.percentage && selectedType != "withdraw_fee") ||
      //   (!newFee.percentage && selectedType != "contract_fee") ||
      //   !newFee.type
      // ) {
      //   toast.error("All fields are required");
      //   setIsLoading(false);
      //   return;
      // }
      if (
        !["approver_fee", "contract_fee", "safebox_fee", "withdraw_fee", "election_fee", "project_fee","game"].includes(
          newFee.type
        ) &&
        !newFee.sub_type
      ) {
        toast.error("Category is required for the selected fee type");
        setIsLoading(false);
        return;
      }
      setIsLoading(true);
      const res = await addFee(newFee);
      toast.success(res?.data?.Message)
      fetchData();
      setIsNewModalOpen(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(error?.response?.data?.Message);
    }
  };
  const handleSymbolChange = (e) => {
    handleNewFeeChange("symbol", e.target.value);
  };
  const handletypeChange = (e) => {
    const selectedType = e.target.value;
    setSelectedType(selectedType)
    handleNewFeeChange("type", e.target.value);
    setEditedFee({
      ...editedFee,
      categoryId: e.target.value,
    });
    setShowSecondDropdown(
      !["approver_fee", "contract_fee", "safebox_fee", "withdraw_fee", "election_fee", "project_fee",'game'].includes(
        selectedType
      )
    );
  };

  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: 'rgba(255, 255, 255, 0.8)',
          zIndex: 99999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>

      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <HeaderWrap>
              {/* <Button onClick={handleNewFee}>Add New Fee</Button> */}
              <div className="col-md-3 col-sm-12">
                <FormButton variant="outline-primary" type="submit" onClick={handleNewFee}>Add New Fee
                </FormButton>
              </div>
              <div className="col-md-3 col-sm-12">
                <InputDiv>
                  <label
                    htmlFor="email"
                    className="text-white bg-transparent"
                    style={{ fontSize: "small" }}
                  >
                    Fee Type
                  </label>
                  <CustomSelect
                    // value={selectedFilterType}
                    onChange={(value) => {

                      setSelectedFilterType(value.value);
                    }}
                    placeholder={selectedFilterType || "Select Fee Type"}
                    options={filterTypeOption}
                    className={'bg-transparent'}
                  />
                </InputDiv>
              </div>
              {/* <div className="col-md-3 col-sm-12 flex justify-center">
                <FormButton variant="outline-danger" type="submit" onClick={handleNewFee}>Reset
                </FormButton>
              </div> */}
            </HeaderWrap>

            <ReactTableBase
              key={
                withSearchEngine || isResizable || isEditable
                  ? "modified"
                  : "common"
              }
              // columns={reactTableData}
              columns={customColumns}
              data={rows}
            />
          </CardBody>
        </Card>
      </Col>
      <StyledModal
        className="h-max"
        style={{ display: isModalOpen ? "block" : "none" }}
      >
        <ModalCloseButton onClick={handleModalClose}>
          <i class="fa fa-times" aria-hidden="true"></i>
        </ModalCloseButton>
        <h2 className="text-center mb-4">New Fee</h2>
        <label htmlFor="newFeeInput" className="block text-white mb-2">
          New Fee:
        </label>
        <input
          id="newFeeInput"
          type="text"
          value={editedFee.new_fee}
          onChange={(e) => {
            if ((editedFee.type === "withdraw_fee" || editedFee.type === "contract_fee" || selectedType == "election_fee" || selectedType == "project_fee") && editedFee.new_percentage) {
              toast.error("You can add only one fee");
              return false;
            }
            const { value } = e.target;
            if (/^\d*\.?\d*$/.test(value)) {
              setEditedFee({ ...editedFee, new_fee: value });
            }
          }}
          className="block w-full border border-gray-300 rounded-md p-1 my-2 text-dark"
        />


        {selectedType != "election_fee" && selectedType != "project_fee" && <><label htmlFor="newPercentageInput" className="block text-white mb-2">
          New Percentage:
        </label><select
          value={editedFee.new_percentage}
          onChange={(e) => {
            if ((editedFee.type === "withdraw_fee" || editedFee.type === "contract_fee" || selectedType == "election_fee" || selectedType == "project_fee") && editedFee.new_fee) {
              setEditedFee({ ...editedFee, new_percentage: "" });
              toast.error("You can add only one fee");
            } else {
              setEditedFee({ ...editedFee, new_percentage: e.target.value });
            }
          }}
          className="block w-full border border-gray-300 rounded-md p-1 my-2 text-dark"
        >
            <option value="">Select a percentage</option>
            {[...Array(101).keys()].map((percentage) => (
              <option key={percentage} value={percentage}>
                {percentage}%
              </option>
            ))}
          </select></>}



        <label
          htmlFor="newPercentageInput"
          hidden={!editedFee.categoryId}
          className="block text-white mb-2"
        >
          New Cateogory:
        </label>
        <select
          value={editedFee.categoryId}
          onChange={handletypeChange}
          className={`block w-full border border-gray-300 rounded-md p-1 my-2 ${editedFee.categoryId
            ? "text-dark"
            : "text-gray-400 cursor-not-allowed disabled"
            }`}
          hidden={!editedFee.categoryId}
        >
          <option value="" hidden={!editedFee.categoryId}>
            Select a type
          </option>
          {FeeCategory.map((symbol, index) => (
            <option
              key={index}
              value={symbol._id}
              selected={symbol._id === editedFee.categoryId}
            >
              {symbol.category_name}
            </option>
          ))}
        </select>
        <div className="flex justify-between mt-4">
          <button
            onClick={handleSave}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 w-full rounded ml-2 mt-2"
          >
            Save
          </button>

        </div>
      </StyledModal>

      <StyledModal
        className="h-max"
        style={{ display: isNewModalOpen ? "block" : "none" }}
      >
        <ModalCloseButton onClick={handleModalClose}>

          <i class="fa fa-times" aria-hidden="true"></i>

        </ModalCloseButton>
        <h2 className="text-center mb-4">New Fee</h2>
        <div className="flex justify-between mt-4 gap-4">

          <select
            value={newFee.type}
            onChange={handletypeChange}
            className="block w-full border border-gray-300 rounded-md p-1 my-2 text-dark mb-2 "
          >
            <option value="">Select a type</option>
            {type.map((symbol, index) => (
              <option key={index} value={symbol}>
                {symbol}
              </option>
            ))}
          </select>
          <select
            value={newFee.symbol}
            onChange={handleSymbolChange}
            className="block w-full border border-gray-300 rounded-md p-1 my-2 text-dark mb-2"
          >
            <option value="">Select a symbol</option>
            {
              selectedType === "withdraw_fee" ?
                modifiedResponse.map((symbol, index) => (
                  <option key={index} value={symbol.value}>
                    {symbol.label}
                  </option>
                ))
                : selectedType == "sale_tax" || selectedType == "approver_fee" ?
                  symbolsWithEuro.map((symbol, index) => (
                    <option key={index} value={symbol}>
                      {symbol}
                    </option>
                  ))
                  : selectedType == "election_fee" || selectedType == 'project_fee' ?
                    symbolsForElectionAndProject.map((symbol, index) => (
                      <option key={index} value={symbol}>
                        {symbol}
                      </option>
                    ))

                    :
                    symbols.map((symbol, index) => (
                      <option key={index} value={symbol}>
                        {symbol}
                      </option>
                    ))
            }
          </select>


        </div>
        <div className="flex justify-between mt-4">
          <select
            value={newFee.sub_type}
            onChange={(e) => setNewFee({ ...newFee, sub_type: e.target.value })}
            className={`block w-9/12 h-8 border border-gray-300 rounded-md p-1 my-2 text-dark mb-2 ${!showSecondDropdown && "text-gray-400 cursor-not-allowed"
              }`}
            disabled={!showSecondDropdown}
          >
            <option value="">Select a category</option>
            {FeeCategory.map((category, index) => (
              <option key={index} value={category._id}>
                {category.category_name}
              </option>
            ))}
          </select>
          <div className="flex justify-between ">
            <input
              type="text"
              value={newFee.fee}
              onChange={(e) => {
                const { value } = e.target;

                if ((selectedType === "withdraw_fee" || selectedType === "contract_fee" || selectedType == "election_fee" || selectedType == "project_fee") && newFee.percentage) {
                  toast.error("You can add only one fee");
                  return;
                }
                if (/^\d*\.?\d*%?$/.test(value)) { // Allow percentage sign (%)
                  setNewFee({ ...newFee, fee: value });
                }
              }}
              className="block w-full border border-gray-300 rounded-md p-1 my-2 text-dark mb-2 ml-3"
              placeholder="New Fee"
            />

            {selectedType != "election_fee" && selectedType != "project_fee" && <select
              value={newFee.percentage}
              onChange={(e) => {

                if (selectedType === "withdraw_fee" && newFee.fee || selectedType === "contract_fee" || selectedType == "election_fee" || selectedType == "project_fee" && newFee.fee) {
                  toast.error("You can add only one fee");
                  return;
                }

                setNewFee({ ...newFee, percentage: e.target.value })
              }
              }
              className={`block w-full border border-gray-300 rounded-md p-1 my-2 text-dark mb-2 ml-4 `}
            >
              <option value="">Select a percentage</option>
              {[...Array(101).keys()].map((percentage) => (
                <option key={percentage} value={percentage}>
                  {percentage}%
                </option>
              ))}
            </select>}
          </div>
        </div>

        <div className="flex justify-center mt-4">
          <button
            onClick={handleSaveNewFee}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2 mt-2"
          >
            Save
          </button>
        </div>
      </StyledModal>
      {showTfaModal && renderTfaModal()}
    </>
  );
};

DataReactTable.propTypes = {
  reactTableData: PropTypes.shape({
    tableHeaderData: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableHeaderData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableRowData: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
};

export default DataReactTable;

// region STYLES

const HeaderWrap = styled.div`
  display: flex;

  & > div:first-child {
    margin-right: auto;
  }
`;
// const ModalCloseButton = styled.button`
//   position: absolute;
//   top: 15px;
//   right: 15px;
//   font-size: 14px;
//   width: 14px;
//   height: 14px;
//   cursor: pointer;
//   padding: 0;
//   border: none;
//   background: transparent;
//   color: #000; /* Replace with your desired color */
// `;

const StyledModal = styled.div`
  /* Centered modal */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  width: 50%; /* Adjusted to fit content */
  height: auto; /* Adjusted to fit content */
  max-width: 90%; /* Set a maximum width if needed */
  max-height: 90%; /* Set a maximum height if needed */
  overflow: auto; /* Enable scrolling if the content exceeds the modal size */
  background-color: #1f1f1f;
  color: ${colorText};
  padding: 20px;
  border: 1px solid #000;
  border-radius: 10px;
  @media (max-width: 768px) { /* Tablet size breakpoint */
  width: 90%;
}
`;

const ModalCloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 14px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  color: red; /* Set the color to red */
`;

// const StyledModal = styled(BootstrapModal)`
//   .modal-dialog {
//     max-width: 50%;
//     top: 50%;
//     transform: translateY(-50%) !important;

//     @media(max-width: 767px) {
//       max-width: 100%;
//       height: 100%;
//       top: 0;
//       transform: translateY(0) !important;
//       margin: 0;
//     }
//   }

//   .modal-content {
//     border-radius: 0;
//     border: none;
//     box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
//     position: relative;
//     padding: 50px 40px 60px 40px;
//     text-align: center;
//     background-color: #1f1f1f;
//     color: ${colorText};

//     @media(max-width: 767px) {
//       height: 100%;
//       align-items: center;
//       justify-content: center;
//     }

//     @media(max-width: 600px) {
//       padding: 16px;

//       .slick-slide {
//         padding: 0;
//       }
//     }
//   }
// `;

const ModalFooter = styled(ButtonToolbar)`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 0;

  button {
    min-width: 100px;
    padding: 4px 25px;
    margin-bottom: 0;
    display: inline-block;
  }

  flex-flow: ${flexFlow} !important;
`;

const ModalTitle = styled.h4`
  margin-top: 10px;
  margin-bottom: 15px;
  font-weight: 700;

  &:first-child {
    margin-top: 0;
  }
`;

// const ModalCloseButton = styled.button`
//   position: absolute;
//   top: 15px;
//   ${right}: 15px;
//   font-size: 14px;
//   width: 14px;
//   height: 14px;
//   cursor: pointer;
//   padding: 0;
//   border: none;
//   background: transparent;
//   color: ${colorIcon};
// `;

const ModalTitleIcon = styled.span`
  width: 24px;
  height: 24px;
  font-size: 24px;
`;
const FormButton = styled(Button)`
 color:"white";
 margin-top: 1rem;
 `;
const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
  input {
    background-color: transparent;
    border: none;
    color:white;
    border-bottom:1px solid #555555;
  }
  input.disabled {
    cursor: not-allowed !important;
    color: #d9d9d9;
  }
`;
// endregion