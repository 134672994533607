import { Modal as BootstrapModal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify"; // Assuming you're using react-toastify

const LocationInfoModal = ({ hide, show, data }) => {

    const truncateValue = (str, start = 6, end = 6) => {
        if (str.length <= start + end) return str; // No truncation if value is too short
        return `${str.slice(0, start)}...${str.slice(-end)}`;
    };

    return (
        <BootstrapModal
            show={show}
            onHide={hide}
            centered
            className="dark:bg-gray-800"
        >
            <BootstrapModal.Header
                closeButton
                className="text-black dark:border-gray-700"
            >
                <BootstrapModal.Title>Location Details</BootstrapModal.Title>
            </BootstrapModal.Header>
            <BootstrapModal.Body className="dark:text-white">

            {/* <span className="flex flex-row gap-2"><strong className="text-black">Login From:</strong><p className="text-black">{data?.login_type || '...'}</p></span> */}
                {data.location?.length > 0 ? (
                    <div className="flex flex-col gap-2">
                        {data.location[0]?.continent && (
                            <p className="text-black">
                                <strong>Continent:</strong> {data.location[0]?.continent}
                            </p>
                        )}
                        {data.location[0]?.country && (
                            <p className="text-black">
                                <strong>Country:</strong> {data.location[0]?.country}
                            </p>
                        )}
                        {data.location[0]?.capital && (
                            <p className="text-black">
                                <strong>Capital:</strong> {data.location[0]?.capital}
                            </p>
                        )}
                        {data.location[0]?.city && (
                            <p className="text-black">
                                <strong>City:</strong> {data.location[0]?.city}
                            </p>
                        )}
                        {data.location[0]?.ip && (
                            <p className="text-black">
                                <strong>Ip Address:</strong> {data.location[0]?.ip}
                            </p>
                        )}
                        {data.location[0]?.longitude && (
                            <p className="text-black">
                                <strong>Longitude:</strong> {data.location[0]?.longitude}
                            </p>
                        )}
                        {data.location[0]?.latitude && (
                            <p className="text-black">
                                <strong>Latitude:</strong> {data.location[0]?.latitude}
                            </p>
                        )}
                    </div>
                ) : (
                    <h3 className="text-black">No data found!</h3>
                )}

                {/* Horizontal rule */}
                <hr className="my-4 border-gray-300 dark:border-gray-600" />


                {/* Wallet Address Section */}
                <div className="flex flex-col gap-2">
                    <h4 className="text-black font-semibold dark:text-white">
                        Wallet Address
                    </h4>
                    {data?.btcAddress && (
                        <p className="text-black flex items-center gap-2">
                            <strong>BTC Address:</strong>
                            <span
                                className="truncate-value"
                                style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    maxWidth: "150px", // Adjust as needed
                                    display: "inline-block",
                                }}
                                title={data?.btcAddress} // Tooltip for full value
                            >
                                {truncateValue(data?.btcAddress)}
                            </span>
                            <button
                                onClick={() => {
                                    navigator.clipboard.writeText(data?.btcAddress);
                                    toast.success("BTC Address copied to clipboard!");
                                }}
                                className="ml-2 text-blue-500"
                            >
                                <FontAwesomeIcon icon={faCopy} />
                            </button>
                        </p>
                    )}
                    {data?.sol_address && (
                        <p className="text-black flex items-center gap-2">
                            <strong>SOL Address:</strong>
                            <span
                                className="truncate-value"
                                style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    maxWidth: "150px",
                                    display: "inline-block",
                                }}
                                title={data?.sol_address}
                            >
                                {truncateValue(data?.sol_address)}
                            </span>
                            <button
                                onClick={() => {
                                    navigator.clipboard.writeText(data?.sol_address);
                                    toast.success("SOL Address copied to clipboard!");
                                }}
                                className="ml-2 text-blue-500"
                            >
                                <FontAwesomeIcon icon={faCopy} />
                            </button>
                        </p>
                    )}
                    {data?.address && (
                        <p className="text-black flex items-center gap-2">
                            <strong>Virtual Address:</strong>
                            <span
                                className="truncate-value"
                                style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    maxWidth: "150px",
                                    display: "inline-block",
                                }}
                                title={data?.address}
                            >
                                {truncateValue(data?.address)}
                            </span>
                            <button
                                onClick={() => {
                                    navigator.clipboard.writeText(data?.address);
                                    toast.success("Virtual Address copied to clipboard!");
                                }}
                                className="ml-2 text-blue-500"
                            >
                                <FontAwesomeIcon icon={faCopy} />
                            </button>
                        </p>
                    )}
                    {data?.trc_address && (
                        <p className="text-black flex items-center gap-2">
                            <strong>Tron Address:</strong>
                            <span
                                className="truncate-value"
                                style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    maxWidth: "150px",
                                    display: "inline-block",
                                }}
                                title={data?.trc_address}
                            >
                                {truncateValue(data?.trc_address)}
                            </span>
                            <button
                                onClick={() => {
                                    navigator.clipboard.writeText(data?.trc_address);
                                    toast.success("Tron Address copied to clipboard!");
                                }}
                                className="ml-2 text-blue-500"
                            >
                                <FontAwesomeIcon icon={faCopy} />
                            </button>
                        </p>
                    )}
                </div>
            </BootstrapModal.Body>
            <BootstrapModal.Footer className="dark:bg-gray-800 dark:border-t dark:border-gray-700">
                <Button variant="secondary" className="text-black" onClick={hide}>
                    Close
                </Button>
            </BootstrapModal.Footer>
        </BootstrapModal>
    );
};

export default LocationInfoModal;
