import { useEffect, useState } from "react"
import { Button } from "../../../shared/components/Button"
import Panel from "../../../shared/components/Panel"
import AddMobileContentModal from "./AddMobileContentModal"
import { getRecord } from "../../../utils/services/user.service"
import EditMobileContentModal from "./EditMobileContentModal"
import DeleteRecordModal from "./DeleteModileContentModsl"

const MobileContent = () => {
    const [showAddModal, setShowAddModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [reFetch, setRefetch] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState(null)
    const [allRecords, setAllRecords] = useState([])
    const getAllContentRecord = async () => {
        const payload = { limit: 1000000, page_number: 1 }
        const res = await getRecord(payload)
        setAllRecords(res?.data?.data)
    }
    useEffect(() => {
        getAllContentRecord()
    }, [reFetch])
    return (
        <>
            <div>
                <div className="px-4">
                    <Button variant="outline-primary" onClick={() => setShowAddModal(true)}>Add New </Button>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 py-4">
                    {allRecords?.map((item, idx) => (
                        <Panel key={idx} title={item?.title}>
                            <div className="flex flex-col gap-2">
                                <img src={item?.image} alt={item?.title} className="h-[250px] relative object-cover rounded-md" />
                                {/* <h3 className="text-center">{item?.title}</h3> */}
                                <p>{item?.text}</p>
                                <div className="flex gap-2 absolute left-[85%] cursor-pointer top-24 text-white">
                                    <i class="fa fa-pencil" aria-hidden="true" onClick={() => {
                                        setSelectedRecord(item)
                                        setShowEditModal(true)
                                    }
                                    }></i>
                                    <i class="fa fa-trash-o" aria-hidden="true" onClick={() => {
                                        setSelectedRecord(item)
                                        setShowDeleteModal(true)
                                    }}></i>
                                </div>
                            </div>
                        </Panel>
                    ))}
                </div>


                <AddMobileContentModal reFetch={() => setRefetch(!reFetch)} show={showAddModal} hide={() => setShowAddModal(false)} />
                <EditMobileContentModal reFetch={() => setRefetch(!reFetch)} initialData={selectedRecord} show={showEditModal} hide={() => setShowEditModal(false)} />
                <DeleteRecordModal reFetch={() => setRefetch(!reFetch)} recordId={selectedRecord} show={showDeleteModal} hide={() => setShowDeleteModal(false)} />

            </div>
        </>
    )
}
export default MobileContent