import React, { useEffect, useState } from "react";
// import Modal from "@mui/material/Modal";
import { Modal } from "@mui/material";
import moment from "moment";
import { useDispatch } from "react-redux";
import TitleCard from "../../AddSafebox/TitleCard";
import { Ring } from "@uiball/loaders";
import {
  getUserInfo1,
  getOtcMarketplaceRequest,
  updateOtcMarketplaceRequest,
} from "../../../../utils/services/user.service";
import { Modal as BootstrapModal } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, ButtonToolbar } from "@/shared/components/Button";
import "../table.css";
import CreateSubAccount from "./CreateSubAccount";
import styled from "styled-components";
import { Link } from "react-router-dom";

const AccountRequests = () => {
  const itemsPerPage = 20;
  const [StoreData, setOtcRequestsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);

  const [TFA, setTFA] = useState(false);
  const userData = JSON.parse(localStorage.getItem("user"));
  const usermail = userData?.data?.user?.email;
  const [isLoading, setIsLoading] = useState(false);
  const [selectedoptionorder, setoptionSelectorder] = useState("all");
  const [show, setShow] = useState(false);

  const filteredStoreData =
    selectedoptionorder === "all"
      ? StoreData
      : StoreData.filter((store) => store.status === selectedoptionorder);

  useEffect(() => {
    const fetchData = async () => {
      const requestData = {
        email: usermail,
      };

      try {
        const response = await getUserInfo1(requestData);

        if (response.status === 200) {
          setTFA(response.data.User.TFA);
        } else {
          console.error("getUserInfo1 failed:", response);
        }
      } catch (error) {
        console.error("Error getUserInfo1:", error);
      }
    };

    // Initial data fetch
    fetchData();

    // Set up interval to fetch data every second
    const intervalId = setInterval(() => {
      fetchData();
    }, 1000);

    // Cleanup function to clear the interval when the component is unmounted
    return () => {
      clearInterval(intervalId);
    };
  }, [usermail, TFA]);

  function getStatusColorClass(status) {
    switch (status) {
      case "pending":
        return "text-yellow-500";
      case "approved":
        return "text-green-500";
      case "rejected":
        return "text-red-500";
      case "deactivated":
        return "text-gray-500";
      default:
        return "";
    }
  }

  const getStatusBadge = (status) => {
    switch (status) {
      case "pending":
        return (
          <div className="mt-1 bg-yellow-100 text-yellow-800 text-sm font-medium mr-2 px-2 py-2 rounded">
            Pending
          </div>
        );
      case "approved":
        return (
          <div className="mt-1 bg-green-100 text-green-800 text-sm font-medium mr-2 px-1 py-2 rounded">
            Approved
          </div>
        );
      case "rejected":
        return (
          <div className="mt-1 bg-red-100 text-red-800 text-sm font-medium mr-2 px-1 py-2 rounded ">
            Rejected
          </div>
        );
      case "deactivated":
        return (
          <div className="mt-1 bg-gray-100 text-gray-800 text-sm font-medium mr-2 px-1 py-2 rounded">
            Deactivated
          </div>
        );
      default:
        return <div className="badge">Unknown</div>;
    }
  };

  const dispatch = useDispatch();

  const otcRequests = async () => {
    try {
      setIsLoading(true);
      const payload = {
        limit: itemsPerPage,
        page_number: currentPage,
        //   approver_status: selectedoptionorder,
        //   name: emailQuery,
      };
      const response = await getOtcMarketplaceRequest(payload);
      //console.log("response", response);
      if (response.data.response) {
        setOtcRequestsData(response.data.response);

        const totalUsers = response.data.count;
        //console.log("totalUsers", totalUsers);

        const pages = Math.ceil(totalUsers / itemsPerPage);
        //console.log("pages", pages);

        setPageCount(pages);
        setIsLoading(false);
      } else {
        console.error("Not Found");
        setIsLoading(false);
        toast.error("Error fetching store data:");
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Error fetching store data:");
      console.error("Error fetching store data:", error);
    }
  };
  useEffect(() => {
    otcRequests();
  }, [currentPage]);

  const handleStatusChange = async (event, storeId) => {
    // setIsLoading(true);
    const newStatus = event.target.value;
    const payload = {
      status: newStatus,
      request_id: storeId,
    };
    // try {
    //   const res = await updateOtcMarketplaceRequest(payload);
    //   setIsLoading(false);
    //   await otcRequests();
    //   toast.success(res?.data?.Message);
    // } catch (error) {
    //   console.error("An error occurred while updating status:", error);
    //   setIsLoading(false);
    //   toast.error(error?.response?.data.Message);
    // }
  };
  const handleApiChange2 = (event) => {
    const selectedOption = event.target.value;
    setoptionSelectorder(selectedOption);
  };
  const customPaddingStyle = {
    padding: " 6px 4px", // Top, Right, Bottom, Left
  };

  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? "flex" : "none", // Use flex display when loading
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(255, 255, 255, 0.8)",
          zIndex: 999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <TitleCard title="" topMargin="mt-2">
        <h3 className="mb-4"> Sub Accounts Requests</h3>
        <div className="lg:flex lg:space-x-4  ">
          <div className="flex flex-col lg:w-1/4 mb-4 lg:mb-0">
            <select
              onChange={handleApiChange2}
              className=" rounded-sm mr-2"
              style={customPaddingStyle}
              id="filters"
            >
              <option selected={selectedoptionorder === "all"} value="all">
                Select Status
              </option>
              <option
                selected={selectedoptionorder === "approved"}
                value="approved"
              >
                Approved
              </option>
              <option
                selected={selectedoptionorder === "rejected"}
                value="rejected"
              >
                Rejected
              </option>
            </select>
          </div>
        </div>
        <div className="overflow-x-auto w-full">
          <table className="tbody table w-full ">
            <thead>
              <tr>
                <th>OTC Request id</th>
                <th>Account Holder</th>
                <th>Email</th>
                <th>Current Status</th>
                <th>Action</th>
                <th>More Details</th>
                <th>Create Account</th>
              </tr>
            </thead>
            <tbody>
              {filteredStoreData.length > 0 ? (
                filteredStoreData.map((store) => ( 
                  <tr key={store._id}>
                    <td>
                      <div className="flex items-center space-x-3">
                        <div>
                          <div className="">{store?._id}</div>
                        </div>
                      </div>
                    </td>

                    <td>{store?.user_detail[0]?.name}</td>
                    <td>{store?.user_detail[0].email}</td>

                    <td className="text-center">
                      {getStatusBadge(store.status)}
                    </td>

                    <td>
                      <div className="relative inline-block text-black">
                        <select
                          id={`statusDropdown-${store._id}`}
                          onChange={(e) => handleStatusChange(e, store._id)}
                          value={store.status}
                          className="appearance-none w-full bg-white text-stone-950 border border-gray-300 hover:border-gray-400 px-4 py-2 pr-8 rounded leading-tight focus:outline-none focus:border-blue-500"
                        >
                          {store.otc_marketplace_status === "pending" && (
                            <option>pending</option>
                          )}
                          <option
                            value="approved"
                            className={getStatusColorClass("approved")}
                          >
                            Approved
                          </option>
                          <option
                            value="rejected"
                            className={getStatusColorClass("rejected")}
                          >
                            Rejected
                          </option>
                        </select>
                      </div>
                    </td>
                    <td className="flex mt-2 justify-center items-center">
                      <Link to={'/allsubaccounts'} >
                      <button
                        className="text-lime-500 hover:underline mt-0.5 mr-16"
                        // onClick={() => handleEyeButtonClick(store)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                          />
                        </svg>
                      </button>
                      </Link>
                    </td>
                    <td>
                      <div className=" flex justify-start items-center">
                        <FormButton
                          type="submit"
                          variant="contain-success"
                          className=""
                          onClick={() => setShow(true)}
                        >
                          Create Sub Account
                        </FormButton>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6">No Request Available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {show && <CreateSubAccount show={show} hide={() => setShow(false)} />}
      </TitleCard>
    </>
  );
};

export default AccountRequests;
const FormButton = styled(Button)`
  color: "white";
  mergin-top: 1rem;
`;
