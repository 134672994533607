import React, { useState, useEffect } from "react";
import { Modal, Button, Table } from "@mui/material";
import { Modal as BootstrapModal } from "react-bootstrap";

const StoreModal = ({ selectedStore, isModalOpen, closeModal }) => {
  if (!selectedStore) {
    return (
      <Modal open={isModalOpen} onClick={closeModal}>
        <div>No data available</div>
      </Modal>
    );
  }

  const handleDownload = () => {
    if (selectedStore.store_document) {
      const link = document.createElement("a");
      link.href = selectedStore.store_document;
      link.download = "store_document"; // Set the desired filename here
      link.click();
    } else {
      //console.log("store_document URL not available for download");
    }
  };

  const generateFeeHashUrl = () => {
    const REACT_APP_BSC_TRANSACTION_EXPLORER_BASE_URL =
      "https://testnet.bscscan.com/tx/";
    const REACT_APP_MATIC_TRANSACTION_EXPLORER_BASE_URL =
      "https://sepolia.etherscan.io/tx/";
    const REACT_APP_ETH_TRANSACTION_EXPLORER_BASE_URL =
      "https://mumbai.polygonscan.com/tx/";
    const {
      REACT_APP_POLYGON_MATIC,
      REACT_APP_SMART_CHAIN,
      REACT_APP_ETHERIUM_CHAIN,
    } = process.env;

    const chainId = selectedStore.chainId;
    const transactionId = selectedStore.fee_hash;
    //console.log('REACT_APP_POLYGON_MATIC', REACT_APP_POLYGON_MATIC)
    //console.log('REACT_APP_BSC_TRANSACTION_EXPLORER_BASE_URL', REACT_APP_BSC_TRANSACTION_EXPLORER_BASE_URL);

    if (chainId === REACT_APP_SMART_CHAIN) {
      return REACT_APP_BSC_TRANSACTION_EXPLORER_BASE_URL + transactionId;
    } else if (chainId === REACT_APP_POLYGON_MATIC) {
      return REACT_APP_MATIC_TRANSACTION_EXPLORER_BASE_URL + transactionId;
    } else {
      return REACT_APP_ETH_TRANSACTION_EXPLORER_BASE_URL + transactionId;
    }
  };

  return (
    <>
      <BootstrapModal show={isModalOpen} onHide={closeModal} centered>
        <BootstrapModal.Header
          closeButton
          className="dark:border-gray-700 bg-dark text-white"
        >
          <BootstrapModal.Title>Store Details</BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body className="dark:text-white bg-dark text-white">
          <tr className=" ">
            <th className="text-left px-4 py-2 ">Owner Name</th>
            <td className="text-left px-4 py-2 ">{selectedStore.ownerName}</td>
          </tr>
          <tr className=" ">
            <th className="text-left px-4 py-2">Store Owner ID</th>
            <td className="text-left px-4 py-2">
              {selectedStore.storeOwnerId}
            </td>
          </tr>
          <tr className=" ">
            <th className="text-left px-4 py-2">Store ID</th>
            <td className="text-left px-4 py-2">{selectedStore._id}</td>
          </tr>
          <tr className=" ">
            <th className="text-left px-4 py-2">Store Address</th>
            <td className="text-left px-4 py-2">
              {selectedStore.storeAddress}
            </td>
          </tr>
          <tr className=" ">
            <th className="text-left px-4 py-2">Store Description</th>
            <td className="text-left px-4 py-2">
              {selectedStore.storeDescription}
            </td>
          </tr>
          <tr className=" ">
            <th className="text-left px-4 py-2">Store Fee</th>
            <td className="text-left px-4 py-2">
              {selectedStore.amount} {selectedStore.symbol}
            </td>
          </tr>
          <tr className=" ">
            <th className="text-left px-4 py-2">Chain ID</th>
            <td className="text-left px-4 py-2">{selectedStore.chainId}</td>
          </tr>
          <tr className=" ">
            <th className="text-left px-4 py-2">Fee Hash</th>
            <td className="text-left px-4 py-2">
              {selectedStore.fee_hash ? (
                <a
                  href={generateFeeHashUrl()}
                  target="_blank"
                  rel="noopener noreferrer"
                  className=" overflow-hidden whitespace-nowrap"
                >
                  View
                </a>
              ) : (
                "Not available"
              )}
            </td>
          </tr>
          <tr className=" ">
            <th className="text-left px-4 py-2">Store Document</th>
            <td className="text-left px-4 py-2">
              {selectedStore.store_document ? (
                <div>
                  <a
                    href={selectedStore.store_document}
                    download="store_document"
                    target="_blank"
                    rel="noreferrer"
                  >
                    View
                  </a>
                  {/* <Button onClick={handleDownload}>View</Button> */}
                </div>
              ) : (
                "Not available"
              )}
            </td>
          </tr>
          <tr className=" ">
            <th className="text-left px-4 py-2 whitespace-nowrap">Online Delivery Available</th>
            <td className="text-left px-4 py-2">
              {selectedStore.online_delivery_available ? "Yes" : "No"}
            </td>
          </tr>
          <tr className=" ">
            <th className="text-left px-4 py-2">Delivery Countries</th>
            <td className="text-left px-4 py-2 ">
              <div className="flex flex-wrap">
                <span className="text-white">
                  {selectedStore.delivery_available_countries?.join(', ')}
                </span>
              </div>

            </td>
          </tr>
        </BootstrapModal.Body>
        <BootstrapModal.Footer className="dark:bg-gray-800 bg-dark dark:border-t dark:border-gray-700">
          <div className="w-full mt-2 mb-2  flex justify-end ">
            <button
              variant="outlined"
              className="w-16 h-8 mr-4 text-sm border border-red-500 hover:bg-red-500 hover:text-white text-red-500 rounded-md"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </BootstrapModal.Footer>
      </BootstrapModal>
    </>
  );
};

export default StoreModal;
