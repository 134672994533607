import { useEffect, useState } from "react";
import { Ring } from "@uiball/loaders";
import ReactTableBase from "@/shared/components/table/ReactTableBase";
import { getAllTokens, getInternalTransferHistory, getPendingMigration, getToken } from "../../utils/services/user.service";
import { chainIdToName, getStatusBadge } from "../../redux/constants/constans";
import { CustomSelect } from "../../shared/components/CustomSelect";
import styled from 'styled-components';
import { Button } from '@/shared/components/Button';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import numeral from "numeral";
import moment from "moment";
import MigrationConfirmModa from "./MigrationConfirmModal";
import TransferInternalBalanceModal from "./TransferInternalBalanceModal";


const InternalTransferReport = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [InternalTransferData, setInternalTransferData] = useState([]);
    const [allSymbols, setAllSymbols] = useState([]);
    const [selectedSymbol, setSelectedSymbol] = useState('');
    const [toUserId, setToUserId] = useState("");
    const [fromUserId, setFromUserId] = useState("");
    const [user_email, setUserEmail] = useState("");
    const [showInternalTransferModal, setshowInternalTransferModal] = useState(false)
    const [reFetch, setRefetch] = useState(true)
    const Ava = `/img/avat.png`;
    const modifiedResponse = allSymbols
    .filter(item => item.symbol !== 'BTC') // Exclude items where symbol is 'BTC'
    .map(item => ({
      label: item.symbol,
      value: item.symbol,
    }));

    const fetchInternalTransferHistory = async () => {
        setIsLoading(true);
        try {
            const payload = {
                limit: 10000, page_number: 1,
                from_user_id: fromUserId,
                to_user_id: toUserId,
                symbol: selectedSymbol,

            };
            const res = await getInternalTransferHistory(payload);

            setInternalTransferData(res.data?.data);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error("Error fetching news:", error);
        }
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                const fees = await getToken();
                ////console.log('Fees:', fees);
                const data = fees?.data?.user;
                setAllSymbols(data);
            } catch (error) {

                console.error("Error fetching fees:", error);
            }
        };

        fetchData();
    }, []);


    useEffect(() => {
        fetchInternalTransferHistory();
        // fetchAllTokens();

    }, [selectedSymbol, fromUserId, toUserId, reFetch]);


    const header = [
        // { accessor: "from_user_id", Header: "From User Id" },
        // { accessor: "to_user_id", Header: "To User Id" },

        { accessor: "_id", Header: "ID" },
        { accessor: "from_user_name", Header: "From User" },
        { accessor: "to_user_name", Header: "To User" },
        { accessor: "from_user_email", Header: "From User Email" },
        { accessor: "to_user_email", Header: "To User Email" },
        { accessor: "amount", Header: "Amount" },
        { accessor: "createdAt", Header: "Date Created" },
    ];

    const tableConfi2 = {
        isEditable: false,
        isSortable: false,
        isResizable: false,
        isPagination: true,
        withSearchEngine: false,
        manualPageSize: [10, 20, 30, 40],
        placeholder: "Search...",
        withPagination: true,
    };

    const data = [];
    InternalTransferData?.map((item) =>
        data.push({

            _id: (
                <div className="flex gap-1 items-center">
                    <h6
                        className="text-capitalize"
                        style={{ color: "#999999", fontSize: "inherit" }}
                    >
                        {item?._id}
                    </h6>
                    <button
                        onClick={() => {
                            navigator.clipboard.writeText(item?._id);
                            toast.success(" id copied to clipboard!");
                        }}
                        className="ml-2"
                        style={{ color: "lightblue" }}
                    >

                        <FontAwesomeIcon icon={faCopy} />
                    </button>
                </div>
            ),
            to_user_id: (
                <div className="flex gap-1 items-center">
                    <h6
                        className="text-capitalize"
                        style={{ color: "#999999", fontSize: "inherit" }}
                    >
                        {item?.to_user_id}
                    </h6>
                    <button
                        onClick={() => {
                            navigator.clipboard.writeText(item?.to_user_id);
                            toast.success(" id copied to clipboard!");
                        }}
                        className="ml-2"
                        style={{ color: "lightblue" }}
                    >

                        <FontAwesomeIcon icon={faCopy} />
                    </button>
                </div>
            ),
            createdAt: (
                <h6
                    className="text-capitalize"
                    style={{ color: "#999999", fontSize: "inherit" }}
                >
                    {moment(item.createdAt).format('ll') || "--"}
                </h6>
            ),

            from_user_name: (
                <div className="flex flex-row pr-5 items-center  gap-3 ">
                    <img className="flex h-[44px] w-[44px] rounded-3xl" src={item?.from_user[0]?.image || Ava} />
                    <h6 className="text-capitalize text-nowrap">
                        {item?.from_user[0]?.name}
                    </h6>
                </div>
            ),
            from_user_email: (

                <div className="flex gap-1 items-center">
                    <h6
                        className=""
                        style={{ color: "#999999", fontSize: "inherit" }}
                    >
                        {item?.from_user[0]?.email}
                    </h6>
                    <button
                        onClick={() => {
                            navigator.clipboard.writeText(item?.from_user[0]?.email);
                            toast.success("Email copied to clipboard!");
                        }}
                        className="ml-2"
                        style={{ color: "lightblue" }}
                    >

                        <FontAwesomeIcon icon={faCopy} />
                    </button>
                </div>
            ),
            to_user_name: (
                <div className="flex flex-row pr-5 items-center  gap-3 ">
                    <img className="flex h-[44px] w-[44px] rounded-3xl" src={item?.to_user[0]?.image || Ava} />
                    <h6 className="text-capitalize text-nowrap">
                        {item?.to_user[0]?.name}
                    </h6>
                </div>
            ),
            to_user_email: (

                <div className="flex gap-1 items-center">
                    <h6
                        className=""
                        style={{ color: "#999999", fontSize: "inherit" }}
                    >
                        {item?.to_user[0]?.email}
                    </h6>
                    <button
                        onClick={() => {
                            navigator.clipboard.writeText(item?.to_user[0]?.email);
                            toast.success("Email copied to clipboard!");
                        }}
                        className="ml-2"
                        style={{ color: "lightblue" }}
                    >

                        <FontAwesomeIcon icon={faCopy} />
                    </button>
                </div>
            ),
            amount: (
                <h6
                  className="text-capitalize"
                  style={{ color: "#999999", fontSize: "inherit" }}
                >
                  {`${numeral(item.amount || 0).format("0,0.00000")} ${item.symbol}`}
                </h6>
              ),


        })
    );
    return (
        <>
            <div
                className="loader-overlay"
                style={{
                    display: isLoading ? "flex" : "none", // Use flex display when loading
                    justifyContent: "center", // Center horizontally
                    alignItems: "center", // Center vertically
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "rgba(255, 255, 255, 0.8)",
                    zIndex: 999,
                }}
            >
                <Ring size={65} color="#00897B" />
            </div>


            <div className="overflow-x-auto p-3 w-full">
                <h3 className="flex-col mr-3">Migration Report </h3>

                <div className="flex flex-col md:flex-row mt-2 ">
                    {/* <div className="flex flex-col col-12 col-md-3  gap-2">
                        <div>
                            <InputDiv>
                                <label
                                    htmlFor="email"
                                    className="text-white"
                                    style={{ fontSize: "small" }}
                                >
                                    From User Id
                                </label>
                                <input
                                    type="text"
                                    placeholder="Enter User id "
                                    className=" py-2 px-2 rounded-sm text-white  bg-transparent border border-white  "
                                    value={fromUserId}
                                    onChange={(e) => setFromUserId(e.target.value)}
                                />
                            </InputDiv>
                        </div>

                    </div>
                    <div className="flex flex-col col-12 col-md-3  gap-2">
                        <div>
                            <InputDiv>
                                <label
                                    htmlFor="email"
                                    className="text-white"
                                    style={{ fontSize: "small" }}
                                >
                                    To User Id
                                </label>
                                <input
                                    type="text"
                                    placeholder="Enter User id "
                                    className=" py-2 px-2 rounded-sm text-white  bg-transparent border border-white  "
                                    value={toUserId}
                                    onChange={(e) => setToUserId(e.target.value)}
                                />
                            </InputDiv>
                        </div>

                    </div> */}
                    <div className="col-md-3 col-sm-12">
                        <InputDiv>
                            <label
                                htmlFor="email"
                                className="text-white bg-transparent"
                                style={{ fontSize: "small" }}
                            >
                                Symbol
                            </label>
                            <CustomSelect
                                value={selectedSymbol}
                                onChange={(value) => {
                                    setSelectedSymbol(value.label);
                                }}
                                placeholder={selectedSymbol || "Select Symbol"}
                                options={modifiedResponse}
                                className={'bg-transparent text-white'}
                            />
                        </InputDiv>
                    </div>
                    <div className="col-md-3 mt-2 col-sm-12">
                        <InputDiv>
                            {/* <label
                                htmlFor="email"
                                className="text-white bg-transparent"
                                style={{ fontSize: "small" }}
                            >
                                Symbol
                            </label> */}
                            <FormButton variant="outline-danger" onClick={() => {
                                setSelectedSymbol("")
                                setFromUserId("")
                                setToUserId("")

                            }} type="submit" >
                                Reset
                            </FormButton>
                        </InputDiv>
                    </div>
                    
                </div>
                <div className="col-md-3 mt-2 col-sm-12">
                        <InputDiv>
                            {/* <label
                                htmlFor="email"
                                className="text-white bg-transparent"
                                style={{ fontSize: "small" }}
                            >
                                Symbol
                            </label> */}
                            <FormButton variant="outline-primary" onClick={() => {
                                setshowInternalTransferModal(true)

                            }} type="submit" >
                                Transfer Internal Balance
                            </FormButton>
                        </InputDiv>
                    </div>


                <ReactTableBase
                    columns={header}
                    data={data}
                    tableConfig={tableConfi2}
                />
                {
                    InternalTransferData?.length == 0 && (
                        <h3>No data found</h3>
                    )
                }
            </div>
            {showInternalTransferModal && (
                <TransferInternalBalanceModal
                    show={showInternalTransferModal}
                    hide={() => setshowInternalTransferModal(false)}
                    // data={migrationData}
                    // isPending={() => setIsLoading(true)}
                    reFetch={() => { setRefetch(!reFetch) }}

                />
            )}


        </>
    );
};
export default InternalTransferReport;

const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
  input {
    background-color: transparent;
    border: none;
    color:white;
    border-bottom:1px solid #555555;
  }
  input.disabled {
    cursor: not-allowed !important;
    color: #d9d9d9;
  }
`;
const FormButton = styled(Button)`
 color:"white";
 margin-top: 1rem;
 `;