import React, { useEffect, useState } from "react";
import { Ring } from "@uiball/loaders";
import { getUserInfo1 } from "@/utils/services/user.service";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "@/shared/components/Button";
import styled from "styled-components";

import ReactTableBase from "@/shared/components/table/ReactTableBase";
import moment from "moment/moment";

import TitleCard from "../AddSafebox/TitleCard";
import { getTargetData } from "../../../utils/services/user.service";
import EditTargetFinancialModal from "./EditTargetFinaincalModal";
import CreateTargetFinancialModal from "./CreateTargetfinancialModal";
import Panel from "../../../shared/components/Panel";
const TargetFinancial = () => {
  const [TFA, setTFA] = useState(false);
  const userData = JSON.parse(localStorage.getItem("user"));
  const usermail = userData?.data?.user?.email;
  const [isLoading, setIsLoading] = useState(false);
  const [reFetech, setRefetch] = useState(false);
  const [show, setShow] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [targetData, setTargetData] = useState([]);
  const [editData, setEditData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const requestData = {
        email: usermail,
      };
      try {
        const response = await getUserInfo1(requestData);

        if (response.status === 200) {
          setTFA(response.data.User.TFA);
        } else {
          console.error("getUserInfo1 failed:", response);
        }
      } catch (error) {
        console.error("Error getUserInfo1:", error);
      }
    };
    fetchData();
  }, [usermail, TFA]);

  const fetchAllTargetData = async () => {
    setIsLoading(true);
    try {
      const res = await getTargetData();
      setTargetData(res.data.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("🚀 ~ fetchAllTargetData ~ error:", error);
    }
  };
  useEffect(() => {
    fetchAllTargetData();
  }, [reFetech]);

  const header = [
    { accessor: "id", Header: "Id" },
    { accessor: "type", Header: "Type" },
    { accessor: "value", Header: "Value" },
    { accessor: "createdDate", Header: "Created Date" },
    { accessor: "action", Header: "Action" },
  ];
  const tableConfi2 = {
    isEditable: false,
    isSortable: false,
    isResizable: false,
    isPagination: false,
    withSearchEngine: false,
    manualPageSize: [10, 20, 30, 40],
    placeholder: "Search...",
    withPagination: true,
  };

  const data = [];
  targetData.map((item) =>
    data.push({
      id: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item._id}
        </h6>
      ),

      type: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item.type || "--"}
        </h6>
      ),
      value: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item.value || "--"}
        </h6>
      ),
      createdDate: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {moment(item.createdAt).format("MMM Do YY") || "--"}
        </h6>
      ),
      action: (
        <FormButton
          variant="outline-primary"
          type="submit"
          onClick={() => {
            setShow(true);
            setEditData(item);
          }}
        >
          Edit
        </FormButton>
      ),

    })
  );

  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? "flex" : "none", // Use flex display when loading
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(255, 255, 255, 0.8)",
          zIndex: 999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <Panel title="" topMargin="mt-2">
        <h3 className="mb-4"> Target Financial</h3>
        <div className="overflow-x-auto w-full mt-2">
        <FormButton variant="outline-primary" type="submit" onClick={()=>setShowCreateModal(true)}>
          Create New
        </FormButton>
          <ReactTableBase
            columns={header}
            data={data}
            tableConfig={tableConfi2}
          />
        </div>
        {show && (
          <EditTargetFinancialModal
            editData={editData}
            show={show}
            hide={() => setShow(false)}
            reFetech={() => setRefetch(!reFetech)}
          />
        )}
        {showCreateModal && (
          <CreateTargetFinancialModal
            show={showCreateModal}
            hide={() => setShowCreateModal(false)}
          />
        )}
      </Panel>
    </>
  );
};

export default TargetFinancial;
const FormButton = styled(Button)`
  color: "white";
  mergin-top: 1rem;
`;
