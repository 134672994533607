/* eslint-disable max-len */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Panel from '@/shared/components/Panel';
import Tooltip from '@/shared/components/Tooltip';
import { getShortCryptoAddress } from '@/utils/helpers';
import {
    WalletInfoWrapper,
    WalletStatRowTitle,
    WalletStatRowValue,
    WalletStatRowWrapper,
} from './styled';
import { toast } from 'react-toastify';
import { SidebarLinkIcon } from '../../Layout/components/sidebar/SidebarLink';
import { getUserWalletsAndEmail } from '../../../utils/services/user.service';
import { useParams } from 'react-router';
import { Ring } from '@uiball/loaders';

const BalanceReportInfo = () => {
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [walletAddreses, setWalletAddresses] = useState({});
    const onCopyWalletAddress = (item) => {
        navigator.clipboard.writeText(item);
        toast.success('Address Copied !')
    };

    useEffect(() => {
        const fetchBalance = async () => {
            setIsLoading(true)
            const payload = {
                user_id: id
            }
            const res = await getUserWalletsAndEmail(payload)
            setWalletAddresses(res?.data?.data)
            setIsLoading(false)
        }
        fetchBalance()
    }, [id])
    return (
        <Panel xs={12} lg={6} xxl={3} title='Wallet Info' >

            <div
                className="loader-overlay"
                style={{
                    display: isLoading ? "flex" : "none", // Use flex display when loading
                    justifyContent: "center", // Center horizontally
                    alignItems: "center", // Center vertically
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "rgba(255, 255, 255, 0.6)",
                    zIndex: 999000,
                }}
            >
                <Ring size={65} color="#00897B" />
            </div>
            <WalletInfoWrapper>
                <WalletStatRowWrapper>
                    {/* <h4> <b> BTC Address</b></h4> */}

                    <WalletStatRowTitle>{('Email')}</WalletStatRowTitle><WalletStatRowValue variant="small">
                        <span>{getShortCryptoAddress(walletAddreses?.email) || "--"}</span>
                        <Tooltip text="Copy Email address" placement="top">
                            <SidebarLinkIcon className="lnr lnr-book" onClick={() => onCopyWalletAddress(walletAddreses?.email)} />
                        </Tooltip>
                    </WalletStatRowValue>
                    <WalletStatRowTitle>{('BTC Address')}</WalletStatRowTitle>
                    <WalletStatRowValue variant="small">
                        <span>{getShortCryptoAddress(walletAddreses?.btcAddress) || "--"}</span>
                        <Tooltip text="Copy wallet address" placement="top">
                            <SidebarLinkIcon className="lnr lnr-book" onClick={() => onCopyWalletAddress(walletAddreses?.btcAddress)} />
                        </Tooltip>
                    </WalletStatRowValue>
                    <WalletStatRowTitle>{('Virtual Address')}</WalletStatRowTitle>
                    <WalletStatRowValue variant="small">
                        <span>{getShortCryptoAddress(walletAddreses?.address) || "--"}</span>
                        <Tooltip text="Copy wallet address" placement="top">
                            <SidebarLinkIcon className="lnr lnr-book" onClick={() => onCopyWalletAddress(walletAddreses?.address)} />
                        </Tooltip>
                    </WalletStatRowValue>
                    <WalletStatRowTitle>{'TRON Address'}</WalletStatRowTitle><WalletStatRowValue variant="small">
                        <span>{getShortCryptoAddress(walletAddreses?.trc_address) || "--"}</span>
                        <Tooltip text="Copy wallet address" placement="top">
                            <SidebarLinkIcon className="lnr lnr-book" onClick={() => onCopyWalletAddress(walletAddreses?.trc_address)} />
                        </Tooltip>
                    </WalletStatRowValue>
                    <WalletStatRowTitle>{'Sol Address'}</WalletStatRowTitle><WalletStatRowValue variant="small">
                        <span>{getShortCryptoAddress(walletAddreses?.sol_address) || "--"}</span>
                        <Tooltip text="Copy wallet address" placement="top">
                            <SidebarLinkIcon className="lnr lnr-book" onClick={() => onCopyWalletAddress(walletAddreses?.sol_address)} />
                        </Tooltip>
                    </WalletStatRowValue>



                </WalletStatRowWrapper>


            </WalletInfoWrapper>

        </Panel>
    );
};



export default BalanceReportInfo;

const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;

const DropdownDiv = styled.div`
  display: block;
  float: right;
  width: 100%;
  @media screen and (max-width: 876px) {
    width: 100%;
  }
`;