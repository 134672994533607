import Modal from "react-bootstrap/Modal";
import { CustomSelect } from "../../../shared/components/CustomSelect";
import styled from "styled-components";
import { useState } from "react";
import {
  deletePartner,
  deleteOtcMarketPlace,
} from "../../../utils/services/user.service";
import { Ring } from "@uiball/loaders";
import { toast } from "react-toastify";

const DeletePartner = ({ show, hide, reFetch, data }) => {

  const [isLoading, setIsLoading] = useState(false);

  const handleDeletePartner = async () => {
    try {
      setIsLoading(true);
      const payload = {
        id: data,
      };

      const res = await deletePartner(payload);
      toast.success(res.data.Message);
      setIsLoading(false);
      reFetch();
      hide();
    } catch (error) {
      toast.error(error?.response?.data.Message);
      setIsLoading(false);
    }
  };
  return (
    <>
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={hide}
      >
        <div
          className="loader-overlay"
          style={{
            display: isLoading ? "flex" : "none",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(255, 255, 255, 0.8)",
            zIndex: 999,
          }}
        >
          <Ring size={65} color="#00897B" />
        </div>
        <Modal.Header closeButton>
          <Modal.Title className="text-center" style={{ fontSize: "large" }}>
            <b>Delete Partner </b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container padding-bottom-3x p-0">
            <div
              className=" mt-0 p-0 flex flex-col gap-8 justify-center items-center"
              style={{ boxShadow: "none", fontFamily: "sans-serif" }}
            >
              <div>
                <h4 className="text-black">
                  Are You sure you want to Delete the Partner
                </h4>
              </div>
              <div className=" flex w-full gap-4 justify-center items-center">
                <button
                  className="text-white p-1 px-4 py-2 rounded-md bg-success "
                  onClick={handleDeletePartner}
                >
                  Yes
                </button>
                <button
                  className="text-white p-1 px-4 py-2 rounded-md bg-danger "
                  onClick={hide}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default DeletePartner;