import moment from "moment";
import { useEffect, useState } from "react";
import TitleCard from "../AddSafebox/TitleCard";
import { useDispatch } from "react-redux";
import { getUserOrder } from "../../../utils/services/user.service";
// import { openModal } from "../common/modalSlice";
// import { MODAL_BODY_TYPES } from "../../utils/globalConstantUtil";
import OrderModal from "./OrderModal";
import { Ring } from "@uiball/loaders";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./table.css";

function UserOrders() {
  const dispatch = useDispatch();
  const itemsPerPage = 20;
  const [usersOrderData, setUsersOrderData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [selectedoption, setoptionSelect] = useState("all");
  const [selectedoptionorder, setoptionSelectorder] = useState("all");
  const [idQuery, setIdQuery] = useState("");
  const [emailQuery, setEmailQuery] = useState("");
  const [nameQuery, setNameQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [pageCount, setPageCount] = useState(1); // Initialize with 1 page.
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  //console.log("pageCount", pageCount);
  //console.log("idQuery", idQuery);
  //console.log("selectedoption", selectedoption);
  //console.log("selectedoptionorder", selectedoptionorder);
  //console.log("selectedOrder", selectedOrder);
  //console.log("currentPage", currentPage);
  useEffect(() => {
    const getUsers = async () => {
      try {
        setIsLoading(true);
        const payload = {
          order_id: idQuery,
          order_status: selectedoptionorder,
          payment_status: selectedoption,
          limit: itemsPerPage,
          page_number: currentPage,
        };
        const response = await getUserOrder(payload);
        if (response.data.order) {
          setUsersOrderData(response.data.order);

          // Calculate the total number of pages based on the total number of users.
          const totalUsers = response.data.count;

          //console.log("totalUsersorders", totalUsers);

          const pages = Math.ceil(totalUsers / itemsPerPage);
          //console.log("pages", pages);

          setPageCount(pages);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          //console.log("data not found");
        }
      } catch (error) {
        setIsLoading(false);
        //console.log("error", error);
      }
    };
    getUsers();
  }, [currentPage, selectedoptionorder, selectedoption, idQuery]);

  const handleToggle = (userId) => {

    const updatedUsersData = usersOrderData.map((user) => {
      if (user._id === userId) {

        return { ...user, status_2fa: !user.status_2fa };
      }
      return user;
    });


    setUsersOrderData(updatedUsersData);
  };

  const handleEyeButtonClick = (store) => {
    setSelectedOrder(store); // Step 3
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleApplyClick = async () => {
    try {
      setIsLoading(true);
      const payload = {
        order_id: idQuery,
        order_status: selectedoptionorder,
        payment_status: selectedoption,
        limit: itemsPerPage,
        page_number: currentPage,
      };

      const response = await getUserOrder(payload);
      setUsersOrderData(response.data.order);

      const totalUsers = response.data.count;

      const pages = Math.ceil(totalUsers / itemsPerPage);
      //console.log("pages", pages);

      setPageCount(pages);
      //console.log("response", response);
      setIsLoading(false);
      // If you want to handle the results further, you can do so here.
    } catch (error) {
      setIsLoading(false);
      setUsersOrderData([]);
      // toast.error("Order Not Found ")
      console.error("Error fetching data:", error);
    }
  };

  const handleApiChange = (event) => {
    const selectedOption = event.target.value;
    //console.log("selectedOption>>>>>", typeof selectedOption);

    setoptionSelect(selectedOption);
    //console.log("setoptionSelect>>>>>", setoptionSelect);
  };

  const handleApiChange2 = (event) => {
    const selectedOption = event.target.value;
    //console.log("selectedOption>>>>>", typeof selectedOption);

    setoptionSelectorder(selectedOption);
    //console.log("setoptionSelect>>>>>", setoptionSelectorder);
  };


  const getUsersOrders = async () => {
    try {
      setIsLoading(true);
      const payload = {
        order_id: "",
        order_status: selectedoptionorder,
        payment_status: selectedoption,
        limit: itemsPerPage,
        page_number: currentPage,
      };
      const response = await getUserOrder(payload);
      if (response.data.order) {
        setUsersOrderData(response.data.order);

        const totalUsers = response.data.count;

        //console.log("totalUsersorders", totalUsers);

        const pages = Math.ceil(totalUsers / itemsPerPage);
        //console.log("pages", pages);

        setPageCount(pages);
        setIsLoading(false);
      } else {
        //console.log("data not found");
        setIsLoading(false);
      }
    } catch (error) {
      //console.log("error", error);
      setIsLoading(false);
    }
  };
  const reset = () => {
    setIdQuery("");
    setoptionSelect("all");
    setoptionSelectorder("all");
    getUsersOrders(); // This will now see the updated idQuery state.
  };

  const customPaddingStyle = {
    padding: " 6px 4px", // Top, Right, Bottom, Left
  };
  const customPaddingStyle2 = {
    padding: " 6px 12px", // Top, Right, Bottom, Left
  };
  const Ava = `/img/avat.png`;

  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          // background: 'rgba(255, 255, 255, 0.8)',
          zIndex: 999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>

      <TitleCard title="" topMargin="mt-2">
        <h3 className="mb-3">Apply Filters</h3>
        <div className=" lg:flex lg:space-x-4  sm:w-full ">
          <div className="flex flex-col lg:w-1/4 mb-4 lg:mb-0">

            <select
              onChange={handleApiChange2}
              className=" rounded-lg mr-2"
              style={customPaddingStyle}
              id="filters"
            >
              <option selected={selectedoptionorder === "all"} value="all">
                Order Status
              </option>
              <option
                selected={selectedoptionorder === "pending"}
                value="pending"
              >
                Pending
              </option>
              <option
                selected={selectedoptionorder === "delivered"}
                value="delivered"
              >
                Delivered
              </option>
              <option
                selected={selectedoptionorder === "waiting"}
                value="waiting"
              >
                Waiting
              </option>
              <option
                selected={selectedoptionorder === "shipped"}
                value="shipped"
              >
                Shipped
              </option>
              <option
                selected={selectedoptionorder === "completed"}
                value="completed"
              >
                Completed
              </option>
            </select>
          </div>
          <div className="flex flex-col lg:w-1/4 mb-4 lg:mb-0">

            <select
              onChange={handleApiChange}
              value={selectedoption}
              className=" rounded-lg mr-2 "
              style={customPaddingStyle2}
              id="filters"
            >
              <option selected={selectedoptionorder === "all"} value="all">
                Payment Status
              </option>
              <option selected={selectedoptionorder === "paid"} value="paid">
                Paid
              </option>
              <option
                selected={selectedoptionorder === "pending"}
                value="pending"
              >
                Pending
              </option>
              <option
                selected={selectedoptionorder === "refund"}
                value="refund"
              >
                Refund
              </option>
            </select>
          </div>

          <div className="flex flex-col items-start ml-1 w-auto sm:mb-4">

            <input
              className="rounded-lg p-1 w-full "
              type="text"
              placeholder=" Order Id.."
              value={idQuery}
              onChange={(e) => setIdQuery(e.target.value)}
            />
          </div>

          {/* <button
            className="w-16 h-7 rounded-lg bg-green-500 text-sm text-white  ml-2 "
            onClick={handleApplyClick}
          >
            Apply
          </button>
          <button
            className="w-16 h-7 rounded-lg bg-red-500 text-sm text-white  ml-2 "
            onClick={reset}
          >
            Reset
          </button> */}
        </div>
        <div className="overflow-x-auto w-full">
          <table className=" tbody table w-full">
            <thead>
              <tr>
                <th>Order #</th>
                <th>Buyer Name</th>
                <th>Buyer Email</th>
                <th style={{ textAlign: "center", verticalAlign: "middle" }}>
                  Delivery Charges
                </th>
                <th style={{ textAlign: "center", verticalAlign: "middle" }}>
                  Amount
                </th>
                <th style={{ textAlign: "center", verticalAlign: "middle" }}>
                  Payment Status
                </th>
                <th>createdAt</th>
                <th style={{ textAlign: "center", verticalAlign: "middle" }}>
                  Order Status
                </th>
                <th style={{ textAlign: "center", verticalAlign: "middle" }}>
                  More Details
                </th>
              </tr>
            </thead>
            <tbody>
              {usersOrderData.length > 0 ? (
                usersOrderData.map((user) => (
                  <tr key={user._id}>
                    <td>
                      <div className="flex items-center space-x-3">
                        <div>
                          <div className="font-bold">{user._id}</div>
                        </div>
                      </div>
                    </td>

                    <td>
                      <div className="flex items-center space-x-3">

                        <div className="flex flex-row pr-2 items-center gap-3 ">
                          <img className="flex h-[44px]  w-[44px] rounded-3xl" src={user.buyer_detail[0].image || Ava} />
                          <span>
                            {user.buyer_detail[0].name}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="flex items-center space-x-3">
                        <div>
                          <div className="font-bold">{user.buyer_detail[0].email}</div>
                        </div>
                      </div>
                    </td>




                    <td
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                    >
                      {user.total_delivery_charges}
                    </td>
                    <td
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                    >
                      {user.total_amount}{" " + user?.symbol}
                    </td>
                    <td
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                    >
                      {user.Payment_status}
                    </td>
                    <td className="hover-date">
                      {user.createdAt
                        ? moment(user.createdAt).fromNow()
                        : "___________"}
                      <span className="date-on-hover">
                        {user.createdAt
                          ? moment(user.createdAt).format("DD MMMM YYYY")
                          : "___________"}
                      </span>
                    </td>
                    <td
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                    >
                      {user.order_status === "pending" && (
                        <span className="badge badge-warning">Pending</span>
                      )}
                      {user.order_status === "delivered" && (
                        <span className="badge badge-success">Delivered</span>
                      )}
                      {user.order_status === "approved" && (
                        <span className="badge badge-success">Delivered</span>
                      )}
                      {user.order_status === "waiting" && (
                        <span className="badge badge-info">Waiting</span>
                      )}
                      {user.order_status === "shipped" && (
                        <span className="badge badge-primary">Shipped</span>
                      )}
                      {user.order_status === "completed" && (
                        <span className="badge badge-success">Completed</span>
                      )}
                    </td>
                    <td
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                    >
                      <button
                        className="text-lime-500 hover:underline"
                        onClick={() => handleEyeButtonClick(user)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                          />
                        </svg>
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6">No Orders available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* Pagination */}
        {pageCount > 1 && (
          <div className="flex items-center justify-center mt-4">
            <button
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
              className="flex items-center justify-center px-1 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            >
              <span className="sr-only">Previous</span>
              <svg
                className="w-2.5 h-2.5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 1 1 5l4 4"
                />
              </svg>
            </button>
            {/* Pagination links */}
            {pageCount <= 3 ? (
              Array.from({ length: pageCount }).map((_, index) => (
                <button
                  key={index}
                  onClick={() => setCurrentPage(index + 1)}
                  className={`mx-1 py-1 px-2 rounded-md cursor-pointer ${currentPage === index + 1
                    ? "text-blue-600 border-blue-300 bg-blue-50 hover:bg-blue-100"
                    : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    }`}
                >
                  {index + 1}
                </button>
              ))
            ) : (
              <>
                {currentPage > 1 && (
                  <>
                    <button
                      onClick={() => setCurrentPage(1)}
                      className={`mx-1 py-2 px-3 rounded-md cursor-pointer ${currentPage === 1
                        ? "text-blue-600 border-blue-300 bg-blue-50 hover:bg-blue-100"
                        : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        }`}
                    >
                      1
                    </button>
                    {currentPage > 3 && <span className="dots">....</span>}
                  </>
                )}
                {currentPage > 2 && (
                  <button
                    onClick={() => setCurrentPage(currentPage - 1)}
                    className={`mx-1 py-2 px-3 rounded-md cursor-pointer ${currentPage === currentPage - 1
                      ? "text-blue-600 border-blue-300 bg-blue-50 hover:bg-blue-100"
                      : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      }`}
                  >
                    {currentPage - 1}
                  </button>
                )}
                <button
                  className={`mx-1 py-2 px-3 rounded-md cursor-pointer ${currentPage === currentPage
                    ? "text-blue-600 border-blue-300 bg-blue-50 hover:bg-blue-100"
                    : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    }`}
                >
                  {currentPage}
                </button>
                {currentPage < pageCount - 1 && (
                  <button
                    onClick={() => setCurrentPage(currentPage + 1)}
                    className={`mx-1 py-2 px-3 rounded-md cursor-pointer ${currentPage === currentPage + 1
                      ? "text-blue-600 border-blue-300 bg-blue-50 hover:bg-blue-100"
                      : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      }`}
                  >
                    {currentPage + 1}
                  </button>
                )}
                {currentPage < pageCount && (
                  <>
                    {currentPage < pageCount - 2 && (
                      <span className="dots">....</span>
                    )}
                    <button
                      onClick={() => setCurrentPage(pageCount)}
                      className={`mx-1 py-2 px-3 rounded-md cursor-pointer ${currentPage === pageCount
                        ? "text-blue-600 border-blue-300 bg-blue-50 hover:bg-blue-100"
                        : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        }`}
                    >
                      {pageCount}
                    </button>
                  </>
                )}
              </>
            )}
            <button
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === pageCount}
              className="flex items-center justify-center px-1 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            >
              <span className="sr-only">Next</span>
              <svg
                class="w-1.5 h-2.5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
            </button>
          </div>
        )}
      </TitleCard>

      {isModalOpen && selectedOrder && (
        <OrderModal
          selectedOrder={selectedOrder}
          closeModal={closeModal}
          isModalOpen={isModalOpen}
        />
      )}
    </>
  );
}

export default UserOrders;
